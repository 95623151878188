import "./builder.css";
import "../App.css";
import "../pageTypes/BasicPage_Player/components/BaseFreeForm/assets/ffi_style.css";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import DesignerHeader from "../components/Header";
import PageLister from "../components/PageLister/PageLister";
import PageEditor from "../components/PageEditor";
import AdvancedEditor from "../components/advancedDesigner";
import SelectAircraftLiveryModal from "../components/Modals/AircraftLivery/SelectAircraftLiveryModal";
import DesignerModal from "../components/Modals/DesignerModal";
import genericRepositoryService from "../services/genericRepositoryService";

import {
  IPageContext,
  IPagesManagementContext,
  PagesManagementContext,
  PageContext,
  LessonManagementContext,
  ILessonManagementContext,
  ILessonPageActionsContext,
  LessonPageActionsContext,
  ILessonPageAction,
  IFMSObject,
  IAssetManagementContext,
  AssetContext,
  EditorModeContext,
  IEditorModeContext,
  IProceduresObject,
  ILessonAdditionalSettingsContext,
  LessonAdditionalSettingsContext,
} from "./builderContexts";
import { IUpdateLessonResponse } from "../models/UpdateLessonApiInterfaces";
import IApiResponse from "../models/IApiResponse";
import ILessonAsset from "../models/ILessonAsset";
import { IGetFmsModelsResponse } from "../models/IGetFmsModelsResponse";
import { IGetNewLessonDependenciesResponse } from "../models/GetNewLessonDependenciesResponse";
import ILessonMetaRequest from "../models/ILessonMetaRequest";
import ILessonPage from "../models/ILessonPage";
import IGetLessonModes from "../models/IGetLessonModes";
import ILessonPageDto from "../models/ILessonPageDto";
import IOpenLessonResponse, { IOpenedBy } from "../models/IOpenLessonResponse";
import IGetAircraftLessonData from "../models/IGetAircraftLessonData";
import IGetATAData from "../models/IGetATAData";
import GetNewPageResponse from "../models/IGetNewPageResponse";
import backgroundLogo from "../assets/ld-cpat-logo-background.png";
import DeletePagesWarningModal from "../components/Modals/deletePagesWarningModal";
import SelectFmsModal from "../components/Modals/fmsSelection/selectFmsModal";
import IGetLessonTypes from "../models/IGetLessonTypes/IGetLessonTypes";
import IGetLessonStyle from "../models/IGetLessonStyle";
import { useSpring, animated, config } from "react-spring";
import IAsset, { fmsCDU } from "../models/IAsset";
import Portal from "../components/Portal/Portal";
import SelectProceduresModal from "../components/Modals/selectProceduresModal/selectProceduresModal";
import bugJar from "../services/DevUtils/bugJar";
import RefreshModal from "../components/RefreshModal/RefreshModal";
import FailedToSave from "../components/Modals/FailedToSave/FailedToSave";
import { drawerIcon } from "../components/PageLister/DrawerSVGs";
import { builderHelper, IGADataUpdate } from "./builderHelper";
import DeleteConfirmation from "../components/Modals/DeleteConfirmation";
import AssetManager from "../components/AssetManager/AssetManager";
import NarratorDrawer from "../components/NarratorDrawer/NarratorDrawer";
import LessonSettings from "../components/LessonSettings/LessonSettings";
import LessonSize from "../components/LessonSize/LessonSize";
import { IFmsPageManifest } from "../pageTypes/FMS_Player/Interfaces/IFmsPageManifest";
import { ICdu } from "../pageTypes/FMS_Player/Interfaces/ICdu";
import { initialCDU } from "../pageTypes/FMS_Player/fmsDesigner";
import SelectWalkAroundModal from "../components/Modals/SelectWalkAroundModal/SelectWalkAroundModal";
import SelectCabinModal from "../components/Modals/SelectCabinModal/SelectCabinModal";
import { checkPageTypeForVersion } from "../pageTypes/FMS_Player/FMS_utils/__FMS_VersionCheck";
import StopOpenLesson from "../components/Modals/StopOpenLesson";
import { crawl } from "../services/Utils/PageTypeCrawler/PageTypeCrawler";
import { AssetManagerModes } from "../components/AssetManager/AssetManager.types";
import { WysiwygProvider } from "../contexts/WYSIWYG/WysiwygProvider";
import useSettingsToggle, { Settings, settingType } from "../hooks/useSettingsToggle";
import { useSelector } from "react-redux";
import { createLogger } from "../utils";
import MetadataEditor from "../components/ImageMetadataEditor/EditorWrapper";
import { ThemeDTO, usePageColor } from "../contexts/PageColorProvider/PageColorProvider";
import {
  LessonPagesActions,
  useLessonPagesDispatch,
  useLessonPagesState,
} from "../contexts/LessonPagesProvider/LessonPagesProvider";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../contexts/ObjectsProvider";
import RightPanel from "../panels/RightPanel";
import {
  useInteractivityBuilderState,
  SET_ALL_VISIBLE_HOTSPOTS,
  useInteractivityBuilderDispatch,
} from "../contexts/InteractivityBuilderProvider";
import { useInteracitvityHotspotDispatch } from "../contexts/InteractivityHotspotProvider";
import { InteractivityBuilder } from "../lib/interactivity";
import { nanoid } from "../lib/nanoId";
import { useGoToPage } from "../hooks/useGoToPage";
import { DEFAULT_IMAGE, DEFAULT_TEXT_BOX, PM_SHAPE_LIST_KEY } from "../const";
import { validatePageManifestBeforeSave, validatePageManifestOnLoad } from "../utils/Validation/validatePageManifest";
import { TablesDataProvider } from "../components/Tables/TablesDataProvider";
import { produce } from "immer";
import { useMetaVariableStore } from "../lib/SmartObject/store";
import { isTimelineEnabled } from "../utils/Lesson";
import { DrawerContainer } from "../components/DrawerContainer";
import { useAudioManagerStore } from "../contexts/PageAudioManager";
import { useShallow } from "zustand/react/shallow";
import { EffectAudio, EInteractiveAudioType, LanguageType, NarrationAudio } from "../models/IInteractiveAudio";
import { NarrationAudioState } from "../contexts/PageAudioManager/types";
import { getSortedAudios } from "../contexts/PageAudioManager/helpers";
import { audioBlobToApi } from "../panels/TimelinePanel/helpers";
import { TIMELINE_DEFAULT_LENGTH } from "../contexts/TimelineProvider/TimelineProvider";
import { LessonSettingsProvider } from "../components/LessonSettings/LessonSettingsContext/LessonSettingsProvider";

const log = createLogger("Builder");
enum RequiredServerOperation {
  Create = 1,
  Edit = 2,
  Delete = 3,
}
export interface IDirtyPageManifest {
  pageManifest: any;
  pageVersionId: number;
}

export interface IDirtyLessonPage {
  lessonPage: ILessonPage;
  requiredServerOperation: RequiredServerOperation;
}

type BuilderProps = {
  lessonId?: string;
};
export interface AssetManagerController {
  isOpen: boolean;
  mode: AssetManagerModes;
}
// export interface ThreeDModel {
//   assetTypeName: string
// assetVersionId: number
// blobPath: string
// description: string
// displayVersion: string
// isCollection: boolean
// name: string
// }
const starterData: ILessonMetaRequest = {
  additionalCopyrightHolders: [],
  aircraftFamilyId: null,
  aircraftId: -1,
  ataNumberDtos: [],
  ataNumberIds: [],
  authorName: "",
  customTailoredForLmss: [],
  description: "",
  isLessonLibraryItem: false,
  isLessonWhiteTail: false,
  lastLessonUpdateTime: "",
  lessonName: "",
  lessonReferences: [],
  lessonStyle: { lessonStyleId: -1, name: "", nickname: "" },
  lessonTags: [],
  lessonType: {
    name: "",
    nickname: "",
    lessonTypeId: -1,
  },
  lessonVersionDisplayableId: -1,
  lessonVersionId: -1,
  lmsName: "",
  manufacturerId: null,
  minimumPassingScore: -1,
  objectives: [],
  parentLessonId: null,
  logoUpdate: false,
};

interface clonePage {
  PageVersionId: number | undefined;
}
interface newPage {
  PagePlayerType: string;
}
type AddPageRequest = clonePage | newPage;

type id = number | null;
type endpoint = "aircraft" | "manufacturer" | "aircraftfamily" | undefined;
export type FMSObject = {
  cdu: string;
  cduPath: string;
  assetVersionId: number;
  cduObject: ICdu;
};
type WalkAroundObject = {
  modelSet: string;
  assetVersionId: number | undefined;
  role: "Captain" | "FO";
};

type CabinObject = {
  modelSet: string;
  assetVersionId: number | undefined;
  role: "Captain" | "FO";
};

const renderSettings: Settings = {
  updatelivery: settingType.customerenabled,
  assetMetaEditing: settingType.cpatonly,
  assetArchiving: settingType.cpatonly,
  imageCropping: settingType.cpatonly,
  interactivity: settingType.cpatonly,
};

function cleanPageManifest(pageManifest: any): any {
  // Use lodash to deeply clone the pageManifest to handle circular structures
  const clonedPageManifest = _.cloneDeep(pageManifest);

  // Check if the cloned pageManifest has a panoramicList
  if (clonedPageManifest.panoramicList) {
    // Iterate over the panoramicList and remove the moduleRef property
    clonedPageManifest.panoramicList = clonedPageManifest.panoramicList.map((panoramic: any) => {
      // Use delete to remove moduleRef if it exists
      if (panoramic.moduleRef) {
        delete panoramic.moduleRef;
      }
      return panoramic;
    });
  }

  return clonedPageManifest;
}

const Builder: React.FC<BuilderProps> = (props: any) => {
  const isCPaTUser: boolean = useSelector(({ authorizedState }: any) => authorizedState.isCpatUser);
  const lessonPagesDispatch = useLessonPagesDispatch();
  const lessonPagesState = useLessonPagesState();
  const objectsDispatch = useObjectsDispatch();
  const objectsState = useObjectsState();
  const [narrationAudios, audioEffects] = useAudioManagerStore(
    useShallow((state) => [state.narrationAudios, state.audioEffects]),
  );

  const interactivityHotspotDispatch = useInteracitvityHotspotDispatch();
  const interactivityBuilderState = useInteractivityBuilderState();
  const interactivityBuilderDispatch = useInteractivityBuilderDispatch();
  const [actionButtons, setActionButtons] = useState<ILessonPageAction[]>([]);
  const [allAvailableAircraft, setAllAvailableAircraft]: [any, any] = useState(undefined);
  const [arePagesValidated, setArePagesValidated] = useState<boolean>(true);
  const [assetIndex, setAssetIndex] = useState<number>(-1);
  const [assetManagerController, setAssetManagerController] = useState<AssetManagerController>({
    isOpen: false,
    mode: "" as any,
  } as AssetManagerController);
  const [lessonSettingsController, setLessonSettingsController] = useState({
    isOpen: false,
  });
  const [assetTypeId, setAssetTypeId] = useState<number>(-1);
  const [blobUploader, showBlobUploader] = useState<boolean>(false);
  const [current3DObject, setCurrent3DObject] = useState<IProceduresObject>({
    modelSet: "no3DorFms",
    assetVersionId: undefined,
    role: "Captain",
  });
  const [currentAircraftId] = useState(undefined);
  const [currentFMSObject, setCurrentFMSObject] = useState<FMSObject>({
    cdu: "",
    cduPath: "",
    assetVersionId: -1,
    cduObject: initialCDU,
  });
  const [currentlyDisplayedPageIndex, setCurrentlyDisplayedPageIndex] = useState<number>(0);
  const [deletedLessonPages, setDeletedLessonPages] = useState<number[]>([]);
  const [deletePageConfirmationModal, setDeletePageConfirmationModal] = useState(false);
  const [deletePagesWarning, setDeletePagesWarning] = useState<boolean>(false);
  const [dirt, setDirt]: [number, any] = useState(0);
  const [dirtyFmsObject] = useState<FMSObject>({
    cdu: "",
    cduPath: "",
    assetVersionId: -1,
    cduObject: initialCDU,
  });
  const [displayID, setDisplayID] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [failedToSave, setFailedToSave] = useState<boolean>(false);
  const [fmsCdus, setFmsCdus] = useState<fmsCDU[]>([]);
  const [proceduresSelectorBoolean, setProceduresSelectorBoolean] = useState<boolean>(false);
  const [isAnnotationsShown, setIsAnnotationsShown] = useState<boolean>(false);
  const [isFMSSelectorShown, setIsFMSSelectorShown] = useState<boolean>(false);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(true);
  const [isThereA3DPage, setIsThereA3DPage]: [any, any] = useState(false);
  const [lessonAssetList, setLessonAssetList] = useState<ILessonAsset[]>([]);
  const [lessonAssetsNeedingToBeDeleted, setLessonAssetsNeedingToBeDeleted] = useState<ILessonAsset[]>([]);
  const [lessonMetaData, setLessonMetaData] = useState<ILessonMetaRequest>(starterData);
  const [lessonDataModalIsOn, setLessonDataModalToOn] = useState<boolean>(false);
  const [lessonDataSettings, setLessonDataSettings]: [any, any] = useState(undefined);
  const [lessonModes, setLessonModes]: [any, any] = useState(false);
  const [lessonName, setLessonName] = useState<string>("");
  const [lessonPages, setLessonPages] = useState<ILessonPageDto[]>([]);
  const [lessonVersionId, setLessonVersionId] = useState<number>(0);
  const [modalType, setModalType] = useState<string>("");
  // const [pageLoadDidFail, setPageLoadDidFail]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(false);
  const [pageAssetIds, setPageAssetIds] = useState<number[]>([]);
  const [pagePullout, togglePagePullout]: [any, any] = useState(false);
  const [pageTypeToDelete, setPageTypeToDelete] = useState<string>("");
  const [previewMode, setPreviewMode]: ["Demo" | "Practice" | "Perform", any] = useState("Demo");
  const [refreshModal, setRefreshModal]: [boolean, any] = useState(false);
  const [selectedEditorLessonModes, setSelectedEditorLessonModes] = useState<number>(1);
  const [isShowStoperShown, setIsShowStopperShown] = useState<boolean>(false);
  const [showAnnotationAlert, setShowAnnotationAlert] = useState<boolean>(false);
  const [showAdvancedEditor, setShowAdvancedEditor] = useState<boolean>(false);
  const [showLiveryEditor, setShowLiveryEditor] = useState<boolean>(false);
  const [alreadyShowedAnnotationAlert, setAlreadyShowedAnnotationAlert] = useState<boolean>(false);
  const [threeDModels, setThreeDModels] = useState<IAsset[]>([]);
  const [walkaroundModels, setWalkaroundModels] = useState<IAsset[]>([]);
  const [cabinModels, setCabinModels] = useState<IAsset[]>([]);
  const [walkaroundSelectorShowing, setWalkaroundSelectorShowing] = useState(false);
  const [cabinSelectorShowing, setCabinSelectorShowing] = useState(false);
  const [timeSpent, setTimeSpent]: [number, any] = useState(Date.now() / 1000);
  const [currentWalkAroundObject, setCurrentWalkAroundObject] = useState<WalkAroundObject>({
    modelSet: "",
    role: "Captain",
    assetVersionId: undefined,
  });
  const [currentCabinObject, setCurrentCabinObject] = useState<CabinObject>({
    modelSet: "",
    role: "Captain",
    assetVersionId: undefined,
  });
  const [coursewareDeveloperToolsController, setCoursewareDeveloperToolsController] = useState<boolean>(false);
  const [featuresToRender, setFeaturesToRender] = useSettingsToggle(renderSettings);
  const [isSavedLoaded, setIsSavedLoaded]: [boolean, any] = useState(false);
  const { updatelivery } = featuresToRender;
  const [fmsDataWorksheetURL, setFmsDataWorksheetURL] = useState<string | null>(null);
  const [pageColorState] = usePageColor();
  const goToPage = useGoToPage(lessonPages);
  const clearMetaVariables = useMetaVariableStore((s) => s.clearAllMetaVariables);

  async function openLesson(): Promise<void> {
    setFeaturesToRender(isCPaTUser); // returns set of features that are allowed for the logged in user
    // let aircraftMode: [id, endpoint];
    const response: IApiResponse<IOpenLessonResponse> = await genericRepositoryService.openLesson({
      lessonId: props.lessonId,
    });
    await handleOpenedByOthers(response.data.openedByOthers, response.data.lessonVersionId);
    // log('',{response})
    handleApiError(response);
    // setPageLoadDidFail(!response.isSuccess);
    const lessonModesResponse: IApiResponse<IGetLessonModes> = await genericRepositoryService.getLessonModes();
    handleApiError(lessonModesResponse);
    const { lessonModes } = lessonModesResponse.data;
    setLessonModes(lessonModes);
    const { lessonPages, lessonName, lessonVersionId, lessonMeta, lessonVersionDisplayableId } = response.data;
    setDisplayID(lessonVersionDisplayableId);

    // Commented out by Diego, ever since microlessons this condition is not necessary anymore
    // if (lessonPages && lessonPages[0].lessonModeIds?.length === 0) {
    //   //checks if title has no lesson modes and adds all of them
    //   lessonPages[0].lessonModeIds = [1, 2, 3];
    //   lessonPages[0].lessonPageIsDirty = true;
    // }
    if (lessonPages && lessonPages[lessonPages.length - 1].lessonModeIds.length === 0) {
      //checks if exit has no lesson modes and it adds all of them
      lessonPages[lessonPages.length - 1].lessonModeIds = [1, 2, 3];
      lessonPages[lessonPages.length - 1].lessonPageIsDirty = true;
    }

    async function handleOpenedByOthers(openedByOthers: IOpenedBy[] | null, openedLessonVersionId: number) {
      if (!openedByOthers) return;
      if (openedByOthers.length < 1) return;

      if (!isCPaTUser) return; // this is a test function

      const FormatElapsedTime = (sec: number): string => {
        const days = Math.trunc(sec / 60 / 60 / 24);
        if (days == 1) return `${days.toFixed(0)} day`;
        if (days >= 2) return `${days.toFixed(0)} days`;
        return [Math.trunc(sec / 60 / 60), Math.trunc((sec / 60) % 60), Math.trunc(sec % 60)]
          .join(":")
          .replace(/\b(\d)\b/g, "0$1");
      };
      const namelist = openedByOthers.map(
        (item: any, ind: number) => item.userName + " (" + FormatElapsedTime(item.secondsOpen) + " ago)",
      );

      const message =
        `This lesson appears to be open for editing by:\n${namelist.join(
          "\n",
        )}  \n\nPlease coordinate editing to avoid loss of data. ` +
        "Press OK if you are sure it is safe to proceed and reset this lesson for other users. Press Cancel to edit at your own risk.";
      const pressedOk = window.confirm(message);
      if (pressedOk) {
        const response: IApiResponse<any> = await genericRepositoryService.resetOpenedLessonLog({
          lessonVersionId: openedLessonVersionId,
        });
      }
    }

    bugJar(lessonPages);
    setLessonMetaData(lessonMeta);
    setLessonVersionId(lessonVersionId);
    setLessonName(lessonName);
    //potentially delays the rest of the program from running in sync while checking page manifests for IGA objects
    lessonPages.forEach((page) => {
      page.pageManifestIsDirty = validatePageManifestOnLoad(page.pagePlayerType, page.pageManifest);
    });
    const validatedPages: ILessonPageDto[] = await IGADataUpdate(lessonPages);
    if (_.isEqual(validatedPages, lessonPages)) {
      lessonPagesDispatch({
        type: LessonPagesActions.UPDATE_LESSON_PAGES,
        payload: lessonPages,
      });
      setLessonPages(lessonPages);
      resumeOpenLesson(lessonPages, lessonMeta);
    } else {
      setLessonPages(validatedPages);
      lessonPagesDispatch({
        type: LessonPagesActions.UPDATE_LESSON_PAGES,
        payload: validatedPages,
      });
      setArePagesValidated(false);
      setIsShowStopperShown(true);
    }
  }
  useEffect(() => {
    if (lessonPages.length > 0 && !alreadyShowedAnnotationAlert) {
      showAnnotationUpdateModal();
    }
  }, [lessonPages]);

  // search all the PageManifests to determine if we have 3D pages with IGA that need to be updated
  const showAnnotationUpdateModal = () => {
    if (!showAnnotationAlert) {
      const pages = lessonPages.filter(function (page) {
        return page.pagePlayerType === "ThreeDModel" || page.pagePlayerType === "Walkaround";
      });

      for (const lp in pages) {
        for (const igaObject in pages[lp].pageManifest.IGSObject) {
          if (
            !pages[lp].pageManifest.IGSObject[igaObject].aspectRatio &&
            ((pages[lp].pageManifest.IGSObject[igaObject].annotations &&
              pages[lp].pageManifest.IGSObject[igaObject].annotations!.length > 0) ||
              (pages[lp].pageManifest.IGSObject[igaObject].stepType === 1 &&
                pages[lp].pageManifest.IGSObject[igaObject].images.imageHotSpot &&
                pages[lp].pageManifest.IGSObject[igaObject].images.path))
          ) {
            setShowAnnotationAlert(true);
            setAlreadyShowedAnnotationAlert(true);
            break;
          }
        }
      }
    }
  };

  async function resumeOpenLesson(lessonPages: ILessonPageDto[], lessonMeta: any) {
    // if(showAnnotationAlert || isShowStoperShown) {
    //   if (currentFMSObject.cdu === 'no3DorFms') {
    //     setFMSandProcedures(lessonPages);
    //   }
    // }

    setFMSandProcedures(lessonPages);
    getInitial3D();
    // validateFirstFmsPage();
    // setLessonName(lessonName);
    // setLessonVersionId(lessonVersionId);
    lessonDataPopulate(lessonMeta);
    const updatedPages: ILessonPageDto[] = await builderHelper(lessonPages);
    setLessonPages(updatedPages);
    // let aircraft = lessonMeta.aircraftId || lessonMeta.aircraftFamilyId || lessonMeta.manufacturerId;
    /**
     *this has moved to a use effect at bottom dependant on starter data
     *aircraftMode = prepareAircraftMode(lessonMeta)
     *downloadThreeDModels(aircraftMode)
     *downloadFmsModels(aircraftMode)
     *downloadWalkaroundModels(aircraftMode)
     */
    log("LESSON-META-IMPORT", { lessonMeta });
    let threeCounter = 0;
    updatedPages.forEach((e, i) => {
      if (e.pagePlayerType === "ThreeDModel") {
        threeCounter++;
      }
    });
    // if (lessonPages.length <= 7) {
    //   setLessonDataModalToOn(true);
    // }
    if (threeCounter > 0) {
      setIsThereA3DPage(true);
    }

    if (showAnnotationAlert) setShowAnnotationAlert(false);
    if (isShowStoperShown) setIsShowStopperShown(false);
    if (_.some(updatedPages, ["pageManifestIsDirty", true]) && arePagesValidated) {
      saveLesson(updatedPages, lessonMeta);
    }
  }
  function prepareAircraftMode(lessonMeta: any): [id, endpoint] {
    let a,
      f,
      m: number | null = null;
    for (const key in lessonMeta) {
      switch (key) {
        case "aircraftId":
          a = lessonMeta[key];
          break;
        case "aircraftFamilyId":
          f = lessonMeta[key];
          break;
        case "manufacturerId":
          m = lessonMeta[key];
          break;
        default:
          break;
      }
    }
    if (a) {
      return [a, "aircraft"];
    } else if (f) {
      return [f, "aircraftfamily"];
    } else if (m) {
      return [m, "manufacturer"];
    } else {
      return [null, undefined];
    }
  }

  function handleApiError(response: any, flag?: string) {
    if (flag === "failed to save" && response.isSuccess) {
      setErrorMessage("save success");
    }
    if (flag === "fail saving settings" && response.isSuccess) {
      setErrorMessage("success saving settings");
    }
    if (response === "" && flag === "") {
      setErrorMessage("");
    }
    if (!response.isSuccess) {
      if (flag) {
        setErrorMessage(flag);
      } else {
        setErrorMessage(response.errorMessage);
        return;
      }
    }
  }

  type aircraftSpecificityId = number | null; // this will be the id associated with either manufacturerId, aircraftFamilyId, aircraftId
  type aircraftSpecificityEndpoint = "aircraft" | "manufacturer" | "aircraftfamily" | undefined; // these are the enpoints the API requires
  type aircraftMode = [aircraftSpecificityId, aircraftSpecificityEndpoint];
  async function downloadThreeDModels(aircraftMode: aircraftMode) {
    if (aircraftMode[0] !== null && aircraftMode[1] === "aircraft") {
      const response = await genericRepositoryService.getThreeDModel(aircraftMode[0], aircraftMode[1]);
      if (response.isSuccess) {
        setThreeDModels(response.data.assets);
      }
    }
  }
  async function downloadWalkaroundModels(aircraftMode: aircraftMode) {
    if (aircraftMode[0] !== null && aircraftMode[1] === "aircraft") {
      const response = await genericRepositoryService.getWalkaroundModels(aircraftMode[0], aircraftMode[1]);
      if (response.isSuccess) {
        setWalkaroundModels(response.data.assets);
      }
    }
  }

  async function downloadCabinModels(aircraftMode: aircraftMode) {
    if (aircraftMode[0] !== null && aircraftMode[1] === "aircraft") {
      const response = await genericRepositoryService.getCabinModels(aircraftMode[0], aircraftMode[1]);
      if (response.isSuccess) {
        setCabinModels(response.data.assets);
      }
    }
  }

  async function downloadFmsModels(aircraftMode: aircraftMode) {
    if (aircraftMode[0] !== null && aircraftMode[1] === "aircraft") {
      const fmsResponse: IApiResponse<IGetFmsModelsResponse> = await genericRepositoryService.getFmsModels(
        aircraftMode[0],
        aircraftMode[1],
      ); //once API in place we can put aircraftMode[1] as second parameter
      const cdus = await populateFMSThumbs(fmsResponse);
      if (fmsResponse.isSuccess) {
        setFmsCdus(cdus.cdus);
      }
      if (fmsResponse.data.assets.length === 1 && cdus.files.length > 0) {
        if (currentFMSObject.cduObject.foreignObjects.length <= cdus.cdus[0].cduObject.foreignObjects.length) {
          setCurrentFMSObject({
            cdu: cdus.files[0],
            cduPath: `${fmsResponse.data.assets[0].blobPath}/`,
            assetVersionId: fmsResponse.data.assets[0].assetVersionId,
            cduObject: cdus.cdus[0].cduObject,
          });
          const fmsTemplateDocUrlMaybe = `${fmsResponse.data.assets[0].blobPath}/` + "fmstemplate.pdf";
          const responseTemplateDoc: any = await genericRepositoryService.blobDetect(fmsTemplateDocUrlMaybe);
          if (responseTemplateDoc.isSuccess) {
            // not all FMS's will have these docs yet
            setFmsDataWorksheetURL(fmsTemplateDocUrlMaybe);
          }
        }
      }
    }
  }

  const populateFMSThumbs = async (fmsResponse: IApiResponse<IGetFmsModelsResponse>) => {
    const tempCDU: fmsCDU[] = [];
    const fileArr: string[] = [];
    let file = "";
    let json: any = { isSuccess: false, errorMessage: "", data: {} };
    try {
      for (const asset of fmsResponse.data.assets) {
        const thisResponse = await genericRepositoryService.getAssetFromCollection(asset.assetVersionId);
        for (const collection of thisResponse.data.assetCollection) {
          if (_.includes(collection.blobPath, "cdu.json")) {
            json = await genericRepositoryService.blobFetch<ICdu>(collection.blobPath);

            const split = collection.blobPath.split("/");
            file = split[split.length - 1];
          }
        }
        const tempAsset: fmsCDU = {
          ..._.cloneDeep(asset),
          imagePath: "",
          cduObject: json.data,
        };
        const cduResponse = await genericRepositoryService.blobFetch<ICdu>(`${asset.blobPath}/${file}`);
        tempAsset.imagePath = asset.blobPath + "/" + cduResponse.data.image.href;
        tempAsset.cduName = file;
        tempCDU.push(tempAsset);
        fileArr.push(file);
      }
    } catch (e) {
      console.error("Error: " + e);
    }
    return { cdus: tempCDU, files: fileArr };
  };

  const showFMSSelector = () => {
    if (!_.some(lessonPages, ["pagePlayerType", "FMS"]) && fmsCdus.length > 1) {
      setIsFMSSelectorShown(true);
    } else {
      addNewPageAndDisplay(currentlyDisplayedPageIndex, "FMS", "FMS");
    }
  };

  function changePageOrder(newOrderLessonPages: ILessonPageDto[], pageToGoTo?: number): void {
    if (pageToGoTo) {
      ReactDom.unstable_batchedUpdates(() => {
        setCurrentlyDisplayedPageIndex(pageToGoTo);
        setLessonPages((oldLessonPages: ILessonPageDto[]) => {
          return referenceIdLogic(newOrderLessonPages, oldLessonPages);
        });
      });
    } else {
      setLessonPages((oldLessonPages: ILessonPageDto[]) => {
        return referenceIdLogic(newOrderLessonPages, oldLessonPages);
      });
    }
  }

  function referenceIdLogic(nlp: ILessonPageDto[], olp?: ILessonPageDto[]): ILessonPageDto[] {
    const newOrderLessonPages: ILessonPageDto[] = [...nlp].map((lp) => ({
      ...lp,
    }));
    let oldLessonPages: ILessonPageDto[] = [];
    if (olp) oldLessonPages = [...olp].map((lp) => ({ ...lp }));
    oldLessonPages.forEach((el, i) => {
      el.order = i; // somehow the orders are updated too quickly so we need to reset
    });
    //bugJar(newOrderLessonPages)
    newOrderLessonPages.forEach((el, i) => {
      if (el.order !== i) {
        el.lessonPageIsDirty = true;
      }
      el.order = i; //we need to assign this because the delete function does not.
    });

    let referencer_list_new = [];
    referencer_list_new = newOrderLessonPages.filter((page: ILessonPageDto, index: number) => {
      return page.pagePlayerType === "Quiz";
    });
    referencer_list_new.forEach((quizPage: ILessonPageDto, index: number) => {
      newOrderLessonPages.forEach((page: ILessonPageDto, index2: number) => {
        if (page.pageVersionId === quizPage.helperReferencePage) {
          if (page.order > quizPage.order) {
            newOrderLessonPages[quizPage.order].helperReferencePage = null;
          }
        }
      });
    });
    bugJar(newOrderLessonPages);
    return newOrderLessonPages;
  }

  function switchCurrentlyDisplayedPage(pageIndexToDisplay: number): void {
    if (pageIndexToDisplay === currentlyDisplayedPageIndex) {
      return;
    }
    goToPage(pageIndexToDisplay);
    setCurrentlyDisplayedPageIndex(pageIndexToDisplay);
  }

  //#region [rgba(157, 218, 71, 0.35)] delete Function
  const deletePage = async () => {
    if (pageIndexIsSafeToDelete()) {
      log("deletePage", "deleting page, waiting for confirmation");
      const deletionConfirmation = await DeleteConfirmation.show({
        deletePageConfirmationModal: deletePageConfirmationModal,
        setDeletePageConfirmationModal: setDeletePageConfirmationModal,
      });
      if (deletionConfirmation) {
        log("deletePage", "confrmation is a go");
        const pagesToDelete: number[] = deletedLessonPages;
        const filteredLessonPages: ILessonPageDto[] = lessonPages.filter((page, index) => {
          if (index !== currentlyDisplayedPageIndex) {
            if (index > currentlyDisplayedPageIndex) {
              page.lessonPageIsDirty = true;
            }
            return page; // do not move this it will break deleting page;
          }
          return false;
        });
        if (!lessonPages[currentlyDisplayedPageIndex].isClientSideNewPage) {
          const deletedPg = lessonPages[currentlyDisplayedPageIndex].lessonPageId;
          if (deletedPg !== -1) pagesToDelete.push(deletedPg);
        }
        log("filteredLessonPages", filteredLessonPages);
        setLessonPages((oldLessonPages) => referenceIdLogic(filteredLessonPages, oldLessonPages));
        setDeletedLessonPages(pagesToDelete);

        const previousPageIndex = currentlyDisplayedPageIndex - 1;
        const previousPageManifest = filteredLessonPages[previousPageIndex].pageManifest;

        setCurrentlyDisplayedPageIndex(previousPageIndex);

        const clearAllMetaVariables = useMetaVariableStore.getState().clearAllMetaVariables;
        const loadMetaVariableStore = useMetaVariableStore.getState().loadStore;
        const loadNarrationAudio = useAudioManagerStore.getState().loadNarrationAudio;
        const loadEffectAudio = useAudioManagerStore.getState().loadEffectAudio;
        const resetNarration = useAudioManagerStore.getState().resetNarration;
        const resetEffects = useAudioManagerStore.getState().resetEffects;

        clearAllMetaVariables();

        if (previousPageManifest.metaVariables) {
          loadMetaVariableStore(
            previousPageManifest.metaVariables,
            previousPageManifest.metaVariablesData,
            previousPageManifest.animatedMetaVariables ?? [],
          );
        }

        objectsDispatch({
          type: ObjectActionsType.SET_OBJECTS_FROM_PAGE_MANIFEST,
          payload: previousPageManifest,
        });
        lessonPagesDispatch({
          type: LessonPagesActions.DELETE_PAGE,
          // payload: currentlyDisplayedPageIndex,
        });

        /**
         * Loading Audio
         */
        resetNarration();
        resetEffects();

        const previousPageNarrationAudios = previousPageManifest?.narrationAudios ?? [];
        const previousPageAudioEffects = previousPageManifest?.audioEffects ?? [];

        if (previousPageNarrationAudios.length > 0) {
          const audiosNarrationToLoad = getSortedAudios([...previousPageNarrationAudios]) as NarrationAudio[];

          for (const audio of audiosNarrationToLoad) {
            loadNarrationAudio(audio.objectId, audioBlobToApi(audio.input as string), {
              ...audio,
            });
          }
        }

        if (previousPageAudioEffects.length > 0) {
          const audiosEffectsToLoad = getSortedAudios([...previousPageAudioEffects]) as EffectAudio[];

          for (const audio of audiosEffectsToLoad) {
            loadEffectAudio(audio.objectId, audioBlobToApi(audio.input as string), {
              ...audio,
            });
          }
        }
      }
    } else {
      //do nothing
    }
  };
  //#endregion
  const pageIndexIsSafeToDelete = () => {
    const standardPageTypes = [
      "Title Page",
      "Introduction Page",
      "Objective Page",
      "Warning Page",
      "Score Page",
      "Conclusion Page",
      "Exit Page",
    ];
    if (lessonPages[currentlyDisplayedPageIndex]) {
      if (standardPageTypes.includes(lessonPages[currentlyDisplayedPageIndex].name)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const updatePageAsset = (
    assetToAdd?: number | null,
    assetToRemove?: number | null,
    pageVersionId?: number | null,
  ) => {
    let newLessonAssets: ILessonAsset[] = _.cloneDeep(lessonAssetList);
    const deletedAssetsCopy: ILessonAsset[] = _.cloneDeep(lessonAssetsNeedingToBeDeleted);
    const newPageAssetIds: number[] = _.map(pageAssetIds, (id) => {
      return id;
    });
    const newLessonPages: ILessonPageDto[] = _.cloneDeep<ILessonPageDto[]>(lessonPages);
    if (!pageVersionId) {
      pageVersionId = newLessonPages[currentlyDisplayedPageIndex].pageVersionId;
    }

    if (assetToAdd) {
      if (!_.includes(newPageAssetIds, assetToAdd)) {
        newPageAssetIds.push(assetToAdd);
      }

      // looking for a duplicate asset with the same pageVersionId
      const duplicate = newLessonAssets.filter((asset) => {
        return assetToAdd === asset.assetVersionId && pageVersionId === asset.pageVersionId;
      });
      // if we find duplicate then the length will be !1 and we do not want to do anything with this asset.
      if (!duplicate.length) {
        newLessonAssets.push({
          assetVersionId: assetToAdd,
          lessonVersionId: lessonVersionId,
          pageVersionId: pageVersionId,
        });
      }

      if (deletedAssetsCopy.length > 0 && _.some(deletedAssetsCopy, ["assetVersionId", assetToAdd])) {
        deletedAssetsCopy.forEach((asset, index) => {
          if (asset.assetVersionId === assetToAdd) {
            deletedAssetsCopy.splice(index, 1);
          }
        });
      }
    }
    if (assetToRemove && typeof assetToRemove === "number") {
      if (newPageAssetIds.length > 0 && _.includes(newPageAssetIds, assetToRemove)) {
        newPageAssetIds.splice(_.indexOf(newPageAssetIds, assetToRemove), 1);
      }

      if (newPageAssetIds.length > 0 && _.some(newLessonAssets, ["assetVersionId", assetToRemove])) {
        _.forEach(newLessonAssets, (asset, index) => {
          if (asset && asset.assetVersionId === assetToRemove) {
            newLessonAssets.splice(index, 1);
          }
        });
      }

      if (!_.some(deletedAssetsCopy, ["assetVersionId", assetToRemove])) {
        deletedAssetsCopy.push({
          assetVersionId: assetToRemove,
          lessonVersionId: lessonVersionId,
          pageVersionId: pageVersionId,
        });
      }
    }

    newLessonAssets = newLessonAssets.map((asset) => {
      if (typeof asset.assetVersionId === "string") {
        asset.assetVersionId = parseInt(asset.assetVersionId);
        return asset;
      }
      return asset;
    });
    setLessonAssetList(newLessonAssets);
    setLessonAssetsNeedingToBeDeleted(deletedAssetsCopy);
    setPageAssetIds(newPageAssetIds);
  };
  function updatePageManifest(newPageManifest: any, skipDirty = false): void {
    log("updatePageManifest", newPageManifest);
    if (window && window.DEBUG === true) {
      console.trace("updatePageManifest trace");
    }

    if (newPageManifest !== null) {
      const newLessonPages: ILessonPageDto[] = [...lessonPages].map((lp) => ({
        ...lp,
      }));
      newLessonPages[currentlyDisplayedPageIndex].pageManifest = _.cloneDeep(newPageManifest);
      if (!skipDirty) {
        newLessonPages[currentlyDisplayedPageIndex].pageManifestIsDirty = true;
      }
      //as a side not this function is being called everytime you click on the quiz page, intended behavior ? - austin
      setLessonPages((lp) => referenceIdLogic(newLessonPages, lp));
    }
  }

  function updateAllPagesTheme(theme: ThemeDTO) {
    setLessonPages((lp) => {
      const lpCopy = _.cloneDeep(lp);
      lpCopy.forEach((p) => {
        if (
          p.pagePlayerType === "Base" ||
          p.pagePlayerType === "Quiz" ||
          p.pagePlayerType === "FMS" ||
          p.pagePlayerType === "Score"
        ) {
          p.pageManifest.theme = theme;
          p.pageManifestIsDirty = true;
        }
      });
      return lpCopy;
    });
  }

  function doesObjectIncrementorExist(): boolean {
    return !!lessonPages[currentlyDisplayedPageIndex].pageManifest.objectIncrementor;
  }

  function getObjectIncrementor(): number {
    let incrementor = 0;

    if (lessonPages[currentlyDisplayedPageIndex].pageManifest.objectIncrementor) {
      incrementor = lessonPages[currentlyDisplayedPageIndex].pageManifest.objectIncrementor;
    } else {
      lessonPages[currentlyDisplayedPageIndex].pageManifest.objectIncrementor = 500;
      incrementor = 500;
    }

    return incrementor;
  }

  //#region [rgba(12, 130, 236,0.35)] saveLesson
  async function saveLesson(lp?: ILessonPageDto[], lessonMetaParam?: any): Promise<ILessonPageDto[] | void> {
    let lessonPagesCopy: ILessonPageDto[];
    if (lp) lessonPagesCopy = _.cloneDeep(lp);
    else lessonPagesCopy = _.cloneDeep(lessonPages);
    lessonPagesCopy = await builderHelper(lessonPagesCopy);
    function doesAlternateAudioExist() {
      if (lessonPagesCopy) {
        for (let i = 0; i < lessonPagesCopy.length; i++) {
          const pageAudio = lessonPagesCopy?.[i]?.pageManifest?.Audio;
          const pageNarration = lessonPagesCopy?.[i]?.pageManifest?.narrationAudios;
          if ((pageAudio && pageAudio.length > 3) || (pageNarration && pageNarration.length > 1)) {
            return true;
          }
        }
      }
      return false;
    }

    let lessonMetaCopy: any;
    if (lessonMetaParam) lessonMetaCopy = _.cloneDeep(lessonMetaParam);
    else lessonMetaCopy = _.cloneDeep(lessonMetaData);
    setIsSaved(false);

    if (doesAlternateAudioExist() && !lessonMetaCopy?.additionalSettings?.studentLanguages?.[1]) {
      handleApiError({ isSuccess: false }, "FAILED SAVE: Set Alternate Language In The Settings");
      setIsSaved(true);
      return;
    }
    if (lessonMetaCopy.aircraftId) {
      lessonMetaCopy.manufacturerId = null;
      lessonMetaCopy.aircraftFamilyId = null;
    } else if (lessonMetaCopy.aircraftFamilyId) {
      lessonMetaCopy.manufacturerId = null;
    }
    // defaults tab name if empty to the description and name if also absent
    lessonMetaCopy.lessonReferences.forEach((ref: any, index: number) => {
      if (!ref?.referenceTabName) {
        if (ref?.description) {
          lessonMetaCopy.lessonReferences[index].referenceTabName = ref?.description;
        } else {
          lessonMetaCopy.lessonReferences[index].referenceTabName = ref?.name;
        }
      }
    });

    if (lessonMetaCopy.objectives) {
      const objectives = lessonMetaCopy.objectives.map((obj: { name: string; objectiveId?: number | string }) => {
        /**
         * If the ID is a string/nanoid(), we remove it
         * so the DB can autogenerate a int id
         */
        if (typeof obj.objectiveId === "string") {
          return { name: obj.name };
        }

        /**
         * Here we send the objective with the same id
         * that comes from the DB
         */
        return { name: obj.name, objectiveId: obj.objectiveId };
      });

      lessonMetaCopy.objectives = objectives;
    }

    const lessonPagesAddedByClient: ILessonPageDto[] = [];
    const lessonPagesNeedingToBeUpdated: ILessonPage[] = [];
    const pageManifestsNeedingToBeUpdated: IDirtyPageManifest[] = [];

    lessonPagesCopy.forEach((page: ILessonPageDto, index: number) => {
      if (page.isClientSideNewPage) {
        lessonPagesAddedByClient.push(page);
      } else if (page.lessonPageIsDirty) {
        const pageToUpdate: ILessonPage = {
          name: page.name,
          blobPath: page.blobPath,
          order: page.order,
          isScorable: page.isScorable,
          shouldAutoPageForward: page.shouldAutoPageForward,
          lessonVersionId: page.lessonVersionId,
          lessonPageId: page.lessonPageId,
          pageVersionId: page.pageVersionId,
          pageId: page.pageId,
          pagePlayerType: page.pagePlayerType,
          pageAssetIds: page.pageAssetIds,
          helperReferencePage: page.helperReferencePage,
          lessonModeIds: page.lessonModeIds,
        };
        lessonPagesNeedingToBeUpdated.push(pageToUpdate);
      }

      /**
       * THIS IS THE NEW STATE MANAGEMENT SYSTEM MERGE
       */
      const NEW_STATE_currentLessonPage = lessonPagesState.lessonPages[index];
      const isBasePage = page.pagePlayerType === "Base";
      const isFreeForm = page.pageManifest.basePageType === "freeForm";
      //if the page is dirty in the new state manifest
      if (NEW_STATE_currentLessonPage?.pageManifestIsDirty && isBasePage) {
        const NEW_STATE_currentLessonPageManifest = NEW_STATE_currentLessonPage?.pageManifest;
        page.pageManifestIsDirty = true;
        /**
         * if the new state has a timeline, we need to update the page manifest with the new timeline
         */

        if (isFreeForm) {
          if (NEW_STATE_currentLessonPageManifest.hotspots) {
            page.pageManifest.hotspots = NEW_STATE_currentLessonPageManifest.hotspots;
          }
          if (NEW_STATE_currentLessonPageManifest.timeline) {
            page.pageManifest.timeline = NEW_STATE_currentLessonPageManifest.timeline;
          }
          if (
            NEW_STATE_currentLessonPageManifest.panoramicList &&
            NEW_STATE_currentLessonPageManifest.panoramicList.length > 0
          ) {
            page.pageManifest.panoramicList = NEW_STATE_currentLessonPageManifest.panoramicList;
          }
          if (
            NEW_STATE_currentLessonPageManifest.shapeList &&
            NEW_STATE_currentLessonPageManifest.shapeList.length > 0
          ) {
            page.pageManifest.shapeList = NEW_STATE_currentLessonPageManifest.shapeList;
          }
        }

        /**
         * if the new state has a video, we need to update the page manifest with the new video
         */
        page.pageManifest.pageImage = isFreeForm
          ? NEW_STATE_currentLessonPageManifest.pageImage
          : page.pageManifest.pageImage;
        page.pageManifest.annotations = NEW_STATE_currentLessonPageManifest.annotations;
        page.pageManifest.textBlock = isFreeForm
          ? NEW_STATE_currentLessonPageManifest.textBlock
          : page.pageManifest.textBlock;

        if (NEW_STATE_currentLessonPageManifest.video) {
          page.pageManifest.video = NEW_STATE_currentLessonPageManifest.video;
        }
        if (NEW_STATE_currentLessonPageManifest.tables) {
          page.pageManifest.tables = NEW_STATE_currentLessonPageManifest.tables;
        }
        if (NEW_STATE_currentLessonPageManifest.pageScormObject) {
          page.pageManifest.pageScormObject = NEW_STATE_currentLessonPageManifest.pageScormObject;
        }
        if (NEW_STATE_currentLessonPageManifest.smartObjects) {
          page.pageManifest.smartObjects = NEW_STATE_currentLessonPageManifest.smartObjects;
        }
        if (NEW_STATE_currentLessonPageManifest.metaVariables) {
          page.pageManifest.metaVariables = NEW_STATE_currentLessonPageManifest.metaVariables;
        }
        if (NEW_STATE_currentLessonPageManifest.metaVariablesData) {
          page.pageManifest.metaVariablesData = NEW_STATE_currentLessonPageManifest.metaVariablesData;
        }
        if (NEW_STATE_currentLessonPageManifest.animatedMetaVariables) {
          page.pageManifest.animatedMetaVariables = NEW_STATE_currentLessonPageManifest.animatedMetaVariables;
        }
        page.pageManifest.interactivity = NEW_STATE_currentLessonPageManifest.interactivity;
        if (NEW_STATE_currentLessonPageManifest.pageText) {
          page.pageManifest.pageText = NEW_STATE_currentLessonPageManifest.pageText;
        }
        if (NEW_STATE_currentLessonPageManifest.audios) {
          page.pageManifest.audios = NEW_STATE_currentLessonPageManifest.audios;
        }
        if (NEW_STATE_currentLessonPageManifest.narrationAudios) {
          page.pageManifest.narrationAudios = NEW_STATE_currentLessonPageManifest.narrationAudios;
        }
        if (NEW_STATE_currentLessonPageManifest.audioEffects) {
          page.pageManifest.audioEffects = NEW_STATE_currentLessonPageManifest.audioEffects;
        }
        if (NEW_STATE_currentLessonPageManifest.shapeList) {
          page.pageManifest.shapeList = NEW_STATE_currentLessonPageManifest.shapeList;
        }
        /**
         * Moved business rule to here to avoid unwanted UI effect
         */
        if (page.shouldAutoPageForward) {
          const hasAudios = !!(
            NEW_STATE_currentLessonPageManifest.narrationAudios.length +
            NEW_STATE_currentLessonPageManifest.audioEffects.length
          );
          const hasVideo = !!NEW_STATE_currentLessonPageManifest.video;
          const hasTransition = !!NEW_STATE_currentLessonPageManifest.timeline;

          page.shouldAutoPageForward = page.shouldAutoPageForward && (hasAudios || hasVideo || hasTransition);
        }
      }

      // if we save the current page has not had the chance to save in the lessonPagesContext
      if (index === currentlyDisplayedPageIndex && isBasePage) {
        const { metaVariables, metaVariablesData, animatedMetaVariables } = useMetaVariableStore.getState();
        if (metaVariables) {
          /**
           * Saving meta variables
           */
          page.pageManifest.metaVariables = metaVariables;
          page.pageManifest.metaVariablesData = metaVariablesData;
          page.pageManifest.animatedMetaVariables = animatedMetaVariables;
        }
        /**
         * We havent actually saved the objects state if we just save the page manifest withouth going somehwere else
         */
        if (lessonPagesState?.lessonPages?.[index]?.pageManifestIsDirty) {
          if (lessonPagesState.lessonPages[index].pageManifest.interactivity) {
            page.pageManifest.interactivity = lessonPagesState.lessonPages[index].pageManifest.interactivity;
          }
          // timeline is the only thing using this new system.
        }
        const scorm = objectsState?.objectList?.find((object: any) => object?.type === "SCORM");
        const tables = objectsState?.tables;
        const hotspots = objectsState?.hotspots;
        const smartObjects = objectsState?.smartObjects;
        if (objectsState.objectListHistory.length > 0) {
          // edits have been made to the current page, need to mark current page dirty.
          page.pageManifestIsDirty = true;
          if (isFreeForm) {
            page.pageManifest.timeline = {
              animatedObjects: objectsState.animatedObjects,
              sequenceLength: page?.pageManifest?.timeline?.sequenceLength ?? 30,
            };
            page.pageManifest.video = objectsState.videos[0];
            if (scorm) {
              page.pageManifest.pageScormObject = [scorm];
            }
            if (tables) {
              page.pageManifest.tables = tables;
            }
            if (hotspots) {
              page.pageManifest.hotspots = hotspots;
            }
            if (smartObjects) {
              page.pageManifest.smartObjects = smartObjects;
            }
          }

          page.pageManifest.pageImage = isFreeForm ? objectsState.images : page.pageManifest.pageImage;
          page.pageManifest.annotations = objectsState.annotations;
          page.pageManifest.textBlock = isFreeForm ? objectsState.textBoxes : page.pageManifest.textBlock;
          page.pageManifest.panoramicList = objectsState.panoramicList.map((pano) => {
            return {
              ...pano,
              moduleRef: undefined,
            };
          });
          page.pageManifest.shapeList = objectsState.shapeList;

          lessonPagesDispatch({
            type: LessonPagesActions.SET_OBJECTS_ON_PAGE_MANIFEST,
            payload: {
              objects: objectsState.objectList,
              animatedObjects: objectsState.animatedObjects,
              images: objectsState.images,
              textBoxes: objectsState.textBoxes,
              tables: objectsState.tables,
              annotations: objectsState.annotations,
              videos: objectsState.videos,
              scorm,
              hotspots,
              smartObjects,
              panoramicList: objectsState.panoramicList,
              shapeList: objectsState.shapeList,
            },
          });
        }
      }

      if (page.pageManifestIsDirty) {
        const validatedPageManifest = produce(page.pageManifest, (draft: any) => {
          validatePageManifestBeforeSave(page.pagePlayerType, draft);
        });
        const manifestToUpdate: IDirtyPageManifest = {
          pageManifest: validatedPageManifest,
          pageVersionId: page.pageVersionId,
        };
        pageManifestsNeedingToBeUpdated.push(manifestToUpdate);
      }
    });

    let alarm = bugJar(lessonPagesCopy);

    //new pages can not be inserted as updated, specification from Gunnar

    //checks the dirty pages for duplicate orders
    const arrayOfUniqueOrders: number[] = [];
    lessonPagesNeedingToBeUpdated.forEach((el) => {
      if (!arrayOfUniqueOrders.includes(el.order)) {
        arrayOfUniqueOrders.push(el.order);
      } else {
        alarm = true;
      }
    });
    lessonPagesAddedByClient.forEach((el) => {
      if (!arrayOfUniqueOrders.includes(el.order)) {
        arrayOfUniqueOrders.push(el.order);
      } else {
        alarm = true;
      }
    });

    deletedLessonPages.forEach((el: number) => {
      if (el === -1) {
        alarm = true;
      }
    });

    if (!alarm) {
      const svObject = {
        //do a spinner
        pageAssetsCurrent: crawl(lessonPagesCopy),
        lessonPagesToAdd: lessonPagesAddedByClient,
        lessonPagesToDelete: deletedLessonPages,
        lessonPagesToUpdate: lessonPagesNeedingToBeUpdated,
        pageManifestsToUpdate: pageManifestsNeedingToBeUpdated,
        lessonAssetsToRemove: lessonAssetsNeedingToBeDeleted,
        lessonMeta: lessonMetaCopy,
        //if lessonAssetsToAdd is empty OR lessonAssetsToDelete is empty, do not update
      };

      const response: IApiResponse<IUpdateLessonResponse> = await genericRepositoryService.updateLesson(svObject);
      handleApiError(response, "failed to save");
      const info = response.data.addLessonPageResponses;

      if (response.data.lessonMeta) {
        lessonMetaCopy.lastLessonUpdateTime = response.data.lessonMeta.lastLessonUpdateTime;
        lessonMetaCopy.manufacturerId = response.data.lessonMeta.manufacturerId;
        lessonMetaCopy.aircraftFamilyId = response.data.lessonMeta.aircraftFamilyId;
        lessonMetaCopy.aircraftId = response.data.lessonMeta.aircraftId;
        lessonMetaCopy.lessonSizeInBytes = response.data.lessonMeta.lessonSizeInBytes;
        lessonMetaCopy.objectives = response.data.lessonMeta.objectives;
      }
      //
      if (response.data.forceReload && response.data.forceReload === true) {
        setRefreshModal(true);
      }
      if (response.isSuccess && !alarm) {
        // let nlp = _.cloneDeep(lessonPages);
        //stop the spinner
        //page versionID
        if (info) {
          const givePageId = () => {
            for (let i = 0; i < info.length; i++) {
              lessonPagesCopy[info[i].order].lessonPageId = info[i].lessonPageId;
            }
          };
          givePageId();
        }
        const updatedLesssonPages: ILessonPageDto[] = onSaveSuccess(lessonPagesCopy);
        return updatedLesssonPages;
      } else {
        setIsSaved(true); //this is for screen blocking and animation
        setFailedToSave(true); //this is for the failed to save modal
      }
    }

    function onSaveSuccess(nlp: ILessonPageDto[]): ILessonPageDto[] {
      setIsSavedLoaded(false);
      const copy = _.cloneDeep(nlp);
      copy.map((page: ILessonPageDto, index: number) => {
        if ("isClientSideNewPage" in page) {
          page.isClientSideNewPage = false;
        }
        if ("lessonPageIsDirty" in page) {
          page.lessonPageIsDirty = false;
        }
        if ("pageManifestIsDirty" in page) {
          page.pageManifestIsDirty = false;
        }
        return page;
      });

      changePageOrder(copy); //correct values
      setLessonMetaData(lessonMetaCopy);
      setLessonName(lessonMetaCopy.lessonName);
      setIsSaved(true);
      setIsSavedLoaded(true);
      setLessonAssetList([]);
      setLessonAssetsNeedingToBeDeleted([]);
      setDeletedLessonPages([]);
      setTimeSpent(Date.now() / 1000);
      return copy;
    }
  }

  const fetchTemplateManifest = async (pageType: string, pageVersionId?: number): Promise<GetNewPageResponse> => {
    let pageTypeObject: AddPageRequest;

    if (pageVersionId) pageTypeObject = { PageVersionId: pageVersionId as number };
    else pageTypeObject = { PagePlayerType: pageType as string };

    const apiResponse: IApiResponse<GetNewPageResponse> = await genericRepositoryService.addLessonPage(pageTypeObject);

    handleApiError(apiResponse);

    return apiResponse.data as GetNewPageResponse;
  };
  //#endregion
  //#region [rgba(134, 19, 24,0.35)] Add Page
  async function addNewPageAndDisplay(
    pageOrder: number,
    pageType: string,
    name: string,
    pageVersionId?: number | undefined,
    lpOverride?: ILessonPageDto[] | void,
    basePageType?: string,
  ): Promise<void> {
    const lessonsArrayLength: number = lessonPages.length;
    let lessonPagesCopy: ILessonPageDto[];
    //add docs
    if (lpOverride) {
      // the override is a hack to utilize the most current lesson pages possible
      lessonPagesCopy = lpOverride; //sometimes with state calling this once is not enough and needs the freshest state values possible
    } else {
      lessonPagesCopy = _.cloneDeep(lessonPages);
    }

    //ThreeD_PAGE, QUIZ_PAGE, BASIC_PAGE, FMS_PAGE
    if (_.isEmpty(pageType)) {
      // eslint-disable-next-line no-throw-literal
      throw "need page type";
    }
    // throw "pageType needs a value...";

    if (_.isEmpty(name)) throw "name needs a value...";

    pageOrder = _.toNumber(pageOrder);
    if (!_.isFinite(pageOrder)) throw "pageOrder should be a number...";

    if (pageOrder <= 3) {
      pageOrder = 4;
    } else if (pageOrder >= lessonsArrayLength - 3) {
      pageOrder = lessonsArrayLength - 3;
    }

    const setDefaultScorable = (pt: string) => {
      switch (pt) {
        case "Quiz":
        case "FMS":
        case "ThreeDModel":
        case "Walkaround":
          return true;
        default:
          return false;
      }
    };

    const newPageResponse: GetNewPageResponse = await fetchTemplateManifest(pageType, pageVersionId);
    const threeDCheck = current3DObject?.assetVersionId !== undefined && pageType === "ThreeDModel";
    const walkAroundCheck = currentWalkAroundObject.assetVersionId !== undefined && pageType === "Walkaround";
    const cabinCheck = currentCabinObject.assetVersionId !== undefined && pageType === "Cabin";
    if (threeDCheck && current3DObject.assetVersionId) {
      // let assets: {assetsToAdd: ILessonAsset[], assetsToDelete: ILessonAsset[]} = {assetsToAdd: [], assetsToDelete: []}
      newPageResponse.pageManifest.modelSet.modelSet = current3DObject.modelSet;
      newPageResponse.pageManifest.modelSet.assetVersionId = current3DObject.assetVersionId.toString();
      newPageResponse.pageManifest.role = current3DObject.role;
      if (!isThereA3DPage) setIsThereA3DPage(true);
      updatePageAsset(current3DObject.assetVersionId, null, newPageResponse.pageVersionId);
    } else if (walkAroundCheck) {
      newPageResponse.pageManifest.modelSet.modelSet = currentWalkAroundObject.modelSet;
      newPageResponse.pageManifest.modelSet.assetVersionId = currentWalkAroundObject.assetVersionId?.toString();
      newPageResponse.pageManifest.role = currentWalkAroundObject.role;
      updatePageAsset(currentWalkAroundObject.assetVersionId, null, newPageResponse.pageVersionId);
    } else if (cabinCheck) {
      newPageResponse.pageManifest.modelSet.modelSet = currentCabinObject.modelSet;
      newPageResponse.pageManifest.modelSet.assetVersionId = currentCabinObject.assetVersionId?.toString();
      newPageResponse.pageManifest.role = currentCabinObject.role;
      updatePageAsset(currentCabinObject.assetVersionId, null, newPageResponse.pageVersionId);
    } else if (pageType === "FMS") {
      newPageResponse.pageManifest = await handleAddingFMS(newPageResponse.pageVersionId, newPageResponse.pageManifest);
      updatePageAsset(currentFMSObject.assetVersionId, null, newPageResponse.pageVersionId);
      // FmsContext.getPageData
    } else {
      if (!threeDCheck || !walkAroundCheck) {
        let newLessonAssets: ILessonAsset[];
        newLessonAssets = assignAssetsToNewPage(newPageResponse.assetVersionIds, newPageResponse.pageVersionId);
        newLessonAssets = newLessonAssets.map((asset) => {
          if (typeof asset.assetVersionId === "string") {
            asset.assetVersionId = parseInt(asset.assetVersionId);
            return asset;
          }
          return asset;
        });
        setLessonAssetList(newLessonAssets);
      }
    }

    if (pageType === "Quiz" || pageType === "FMS" || pageType === "Base") {
      if (pageColorState.applyingToNewPage === true) {
        newPageResponse.pageManifest.theme = {
          colorFill: pageColorState.colorFill,
          colors: pageColorState.colors,
          showCPaTIcon: pageColorState.showCPaTIcon,
        };
      }
    }

    if (basePageType || pageType === "Base") {
      const newPageManifest = {
        ...newPageResponse.pageManifest,
        basePageType: basePageType,
        pageImage: newPageResponse.pageManifest.pageImage.map((image: any, i: number) => {
          const newImage = {
            ...DEFAULT_IMAGE,
            ...image,
            ...image.attributes,
            objectId: `image-${nanoid()}`,
            type: "pageImage",
            displayName: `Image ${i + 1}`,
            lockAspectRatio: true,
            version: 3,
          };
          delete newImage.attributes;
          return newImage;
        }),
        textBlock: newPageResponse.pageManifest.textBlock.map((block: any, i: number) => {
          const newBlock = {
            ...DEFAULT_TEXT_BOX,
            ...block,
            ...block.attributes,
            objectId: `textbox-${nanoid()}`,
            type: "textBlock",
            displayName: `Text Block ${i + 1}`,
            text: `<p class="standard-blot-block"><span style="font-size: 1em;">Text Box ${i + 1}</span></p>`,
            hasHorizontalPadding: true,
            fontColor: "white",
          };
          delete newBlock.attributes;
          return newBlock;
        }),
        timeline: {
          animatedObjects: [],
          sequenceLength: TIMELINE_DEFAULT_LENGTH,
        },
      };
      const objectList = newPageManifest.pageImage.concat(newPageManifest.textBlock);
      objectList.forEach((object: any, i: number) => {
        object.zIndex = i + 1;
      });
      newPageResponse.pageManifest = newPageManifest;
    }
    bugJar(lessonPagesCopy);

    const newPage = {
      name: name,
      order: pageOrder,
      blobPath: "",
      shouldAutoPageForward: false,
      isScorable: setDefaultScorable(pageType),
      lessonVersionId: lessonVersionId,
      lessonPageId: -1,
      pageId: -1,
      pageManifest: newPageResponse.pageManifest,
      pagePlayerType: pageType,
      pageVersionId: newPageResponse.pageVersionId,
      pageAssetIds: threeDCheck ? [current3DObject.assetVersionId!] : newPageResponse.assetVersionIds,
      isClientSideNewPage: true,
      lessonPageIsDirty: false,
      pageManifestIsDirty: threeDCheck || pageType === "FMS" ? true : false,
      lessonModeIds: pageVersionId ? lessonPagesCopy[pageOrder - 1].lessonModeIds : [1, 2, 3],
      uniquePageListerID: lessonPagesCopy.length - 7,
    };
    lessonPagesCopy.splice(pageOrder, 0, newPage);

    //TODO go to new page hook
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (newPage.pageManifest.interactivity) {
      const interactivityBuilder = new InteractivityBuilder(newPage.pageManifest.interactivity);
      const hotspots = interactivityBuilder.getAllFirstHotspots();
      if (hotspots) {
        interactivityHotspotDispatch({
          type: SET_ALL_VISIBLE_HOTSPOTS,
          payload: hotspots,
        });
      } else {
        interactivityHotspotDispatch({
          type: SET_ALL_VISIBLE_HOTSPOTS,
          payload: [],
        });
      }
      interactivityBuilderDispatch({
        type: "SET_INSTANCE",
        payload: interactivityBuilder,
      });
    } else {
      const interactivityBuilder = new InteractivityBuilder();
      interactivityBuilderDispatch({
        type: "SET_INSTANCE",
        payload: interactivityBuilder,
      });
      interactivityHotspotDispatch({
        type: SET_ALL_VISIBLE_HOTSPOTS,
        payload: [],
      });
    }
    // we save all edits made to the objects on the page before switching pages
    lessonPagesDispatch({
      type: LessonPagesActions.SET_OBJECTS_ON_PAGE_MANIFEST,
      payload: {
        objects: objectsState.objectList,
        animatedObjects: objectsState.animatedObjects,
        images: objectsState.images,
        textBoxes: objectsState.textBoxes,
        tables: objectsState.tables,
        annotations: objectsState.annotations,
        videos: objectsState.videos,
        scorm: objectsState?.objectList?.filter((object) => object?.type === "SCORM")[0],
        hotspots: objectsState?.hotspots,
        smartObjects: objectsState?.smartObjects,
        panoramicList: objectsState?.panoramicList,
        shapeList: objectsState?.shapeList,
      },
    });

    const { metaVariables, metaVariablesData, animatedMetaVariables } = useMetaVariableStore.getState();

    if (metaVariables) {
      lessonPagesDispatch({
        type: LessonPagesActions.UPDATE_CURRENT_PAGE_MANIFEST,
        payload: {
          metaVariables,
          metaVariablesData,
          animatedMetaVariables,
        },
      });
    }

    lessonPagesDispatch({
      type: LessonPagesActions.ADD_NEW_PAGE,
      payload: {
        page: newPage,
        pageIndex: pageOrder,
      },
    });
    // we switch the currently selected page from the lesson pages
    lessonPagesDispatch({
      type: LessonPagesActions.SET_CURRENT_PAGE_INDEX,
      payload: pageOrder,
    });
    // we create a new object list from the new page manifest
    objectsDispatch({
      type: ObjectActionsType.SET_OBJECTS_FROM_PAGE_MANIFEST,
      payload: newPage.pageManifest,
    });
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    lessonPagesCopy.forEach((lesson: ILessonPageDto, index: number) => {
      if (lesson.order !== index) {
        lesson.order = index;
        lesson.lessonPageIsDirty = true;
      }
      if (lessonPagesState.lessonPages) {
        //
      }
    });

    let newLessonPages = builderHelper(lessonPagesCopy);

    newLessonPages = referenceIdLogic(newLessonPages);
    setLessonPages(newLessonPages);
    setCurrentlyDisplayedPageIndex(pageOrder);

    /**
     * We clear the meta variables after we switch pages
     * doing this before causes the rendering of the smart object
     * to crash the lesson designer
     */
    if (metaVariables) {
      clearMetaVariables();
    }
  }
  //#endregion
  const copyPage = async (pageOrder: number, lessonPages: ILessonPageDto[], pageVId?: number) => {
    document.body.classList.add("remove-pointer-events");
    // miscUIDispatch({type: "TOGGLE_COPYING"})
    const pageVersionId: number = lessonPages[currentlyDisplayedPageIndex].pageVersionId;
    const pageType: string = lessonPages[currentlyDisplayedPageIndex].pagePlayerType;
    const lessonPagesAddedByClient: ILessonPageDto[] = [];
    const lessonPagesNeedingToBeUpdated: ILessonPage[] = [];
    const pageManifestsNeedingToBeUpdated: IDirtyPageManifest[] = [];
    let newLessonPages: ILessonPageDto[] = _.cloneDeep(lessonPages);
    const copyPageResponse = await genericRepositoryService.addLessonPage({
      PageVersionId: pageVersionId as number,
    });

    const NEW_STATE_currentPage = lessonPagesState.lessonPages[currentlyDisplayedPageIndex];
    const NEW_STATE_currentPageManifest = NEW_STATE_currentPage?.pageManifest;
    const newPage: ILessonPageDto = {
      name: `${lessonPages[currentlyDisplayedPageIndex].name} Copy`,
      order: pageOrder,
      blobPath: "",
      shouldAutoPageForward: lessonPages[currentlyDisplayedPageIndex].shouldAutoPageForward,
      isScorable: lessonPages[currentlyDisplayedPageIndex].isScorable,
      lessonVersionId: lessonVersionId,
      lessonPageId: -1,
      pageId: -1,
      pageManifest: {
        ...lessonPages[currentlyDisplayedPageIndex].pageManifest,
        pageImage: objectsState.images,
        annotations: objectsState.annotations,
        textBlock: objectsState.textBoxes,
      },
      pagePlayerType: pageType,
      pageVersionId: copyPageResponse.data.pageVersionId,
      pageAssetIds: lessonPages[currentlyDisplayedPageIndex].pageAssetIds,
      isClientSideNewPage: true,
      lessonPageIsDirty: false,
      pageManifestIsDirty: true,
      lessonModeIds: lessonPages[currentlyDisplayedPageIndex].lessonModeIds,
      uniquePageListerID: lessonPages.length - 7, // new by austin for pageListerHelper
    };

    //////////////////////////////////////////////////////////////////////
    if (objectsState.animatedObjects.length > 0) {
      newPage.pageManifest.timeline = {
        animatedObjects: objectsState.animatedObjects,
        sequenceLength: lessonPages[currentlyDisplayedPageIndex].pageManifest.timeline.sequenceLength,
      };
    }
    if (NEW_STATE_currentPageManifest.interactivty) {
      newPage.pageManifest.interactivity = NEW_STATE_currentPageManifest.interactivity;
    }

    const { metaVariables, metaVariablesData, animatedMetaVariables } = useMetaVariableStore.getState();

    if (metaVariables) {
      newPage.pageManifest.metaVariables = metaVariables;
    }

    if (metaVariablesData) {
      newPage.pageManifest.metaVariablesData = metaVariablesData;
    }

    if (animatedMetaVariables) {
      newPage.pageManifest.animatedMetaVariables = animatedMetaVariables;
    }

    const scorm = objectsState?.objectList?.find((object) => object?.type === "SCORM");
    if (scorm) {
      newPage.pageManifest.pageScormObject = [scorm];
    }
    if (objectsState.videos[0]) {
      newPage.pageManifest.video = objectsState.videos[0];
    }

    if (objectsState.tables) {
      newPage.pageManifest.tables = objectsState.tables;
    }

    if (objectsState.hotspots) {
      newPage.pageManifest.hotspots = objectsState.hotspots;
    }

    if (objectsState.panoramicList) {
      newPage.pageManifest.panoramicList = objectsState.panoramicList;
    }

    if (objectsState.shapeList) {
      newPage.pageManifest.shapeList = objectsState.shapeList;
    }

    if (objectsState.smartObjects) {
      newPage.pageManifest.smartObjects = objectsState.smartObjects;
    }

    //////////////////////////////////////////////////////////////////////

    const initialAudioState = {
      ready: false,
      blob: undefined,
      playing: false,
      loading: false,
    };

    if (narrationAudios && narrationAudios.length > 0) {
      // First pass to change Audio ids
      //  Save the primary ones to redo the reference to secondary
      const primaryIdRemap = new Map();
      newPage.pageManifest.narrationAudios = narrationAudios
        .map((audio) => {
          const newId = nanoid();

          if (audio.language === LanguageType.PRIMARY) {
            primaryIdRemap.set(audio.objectId, newId);
          }

          return { ...audio, ...initialAudioState, objectId: newId };
        })
        .map((audio) => {
          if (audio.parentObjectId) {
            audio.parentObjectId = primaryIdRemap.get(audio.parentObjectId);
          }

          return audio;
        });
    }

    if (audioEffects && audioEffects.length > 0) {
      newPage.pageManifest.audioEffects = audioEffects.map((audio) => ({
        ...audio,
        ...initialAudioState,
        objectId: nanoid(),
      }));
    }

    //////////////////////////////////////////////////////////////////////

    if (newPage.pageManifest.interactivity) {
      const interactivityBuilder = new InteractivityBuilder(newPage.pageManifest.interactivity);
      const hotspots = interactivityBuilder.getAllFirstHotspots();
      if (hotspots) {
        interactivityHotspotDispatch({
          type: SET_ALL_VISIBLE_HOTSPOTS,
          payload: hotspots,
        });
      } else {
        interactivityHotspotDispatch({
          type: SET_ALL_VISIBLE_HOTSPOTS,
          payload: [],
        });
      }
      interactivityBuilderDispatch({
        type: "SET_INSTANCE",
        payload: interactivityBuilder,
      });
    } else {
      const interactivityBuilder = new InteractivityBuilder();
      interactivityBuilderDispatch({
        type: "SET_INSTANCE",
        payload: interactivityBuilder,
      });
      interactivityHotspotDispatch({
        type: SET_ALL_VISIBLE_HOTSPOTS,
        payload: [],
      });
    }
    // we save all the meta variables information on the page before switching pages

    lessonPagesDispatch({
      type: LessonPagesActions.UPDATE_CURRENT_PAGE_MANIFEST,
      payload: {
        metaVariables,
        metaVariablesData,
        animatedMetaVariables,
      },
    });

    // we save all edits made to the objects on the page before switching pages
    lessonPagesDispatch({
      type: LessonPagesActions.SET_OBJECTS_ON_PAGE_MANIFEST,
      payload: {
        objects: objectsState.objectList,
        animatedObjects: objectsState.animatedObjects,
        images: objectsState.images,
        textBoxes: objectsState.textBoxes,
        tables: objectsState.tables,
        annotations: objectsState.annotations,
        videos: objectsState.videos,
        scorm: objectsState?.objectList?.filter((object) => object?.type === "SCORM")[0],
        hotspots: objectsState?.hotspots,
        smartObjects: objectsState?.smartObjects,
        panoramicList: objectsState?.panoramicList,
        shapeList: objectsState?.shapeList,
      },
    });
    lessonPagesDispatch({
      type: LessonPagesActions.ADD_NEW_PAGE,
      payload: {
        page: newPage,
        pageIndex: pageOrder,
      },
    });
    // we switch the currently selected page from the lesson pages
    lessonPagesDispatch({
      type: LessonPagesActions.SET_CURRENT_PAGE_INDEX,
      payload: pageOrder,
    });
    // we create a new object list from the new page manifest
    objectsDispatch({
      type: ObjectActionsType.SET_OBJECTS_FROM_PAGE_MANIFEST,
      payload: newPage.pageManifest,
    });

    /////////////////////////////////////////////////////////////////////////
    newLessonPages.splice(pageOrder, 0, newPage);
    newLessonPages.forEach((page: ILessonPageDto, index: number) => {
      if (page.order !== index) {
        page.order = index;
        page.lessonPageIsDirty = true;
      }
      if (page.isClientSideNewPage) {
        lessonPagesAddedByClient.push(page);
      } else if (page.lessonPageIsDirty) {
        const pageToUpdate: ILessonPage = {
          name: page.name,
          order: page.order,
          isScorable: page.isScorable,
          shouldAutoPageForward: page.shouldAutoPageForward,
          lessonVersionId: page.lessonVersionId,
          lessonPageId: page.lessonPageId,
          pageVersionId: page.pageVersionId,
          pageId: page.pageId,
          blobPath: page.blobPath,
          pagePlayerType: page.pagePlayerType,
          pageAssetIds: page.pageAssetIds,
          helperReferencePage: page.helperReferencePage,
          lessonModeIds: page.lessonModeIds,
        };
        lessonPagesNeedingToBeUpdated.push(pageToUpdate);
      }
      if (page.pageManifestIsDirty) {
        // Use the cleanPageManifest function to get the cleaned pageManifest
        const cleanedPageManifest = cleanPageManifest(page.pageManifest);

        const manifestToUpdate: IDirtyPageManifest = {
          pageManifest: cleanedPageManifest,
          pageVersionId: page.pageVersionId,
        };
        pageManifestsNeedingToBeUpdated.push(manifestToUpdate);
      }
    });
    let alarm = bugJar(newLessonPages);
    const arrayOfUniqueOrders: number[] = [];
    lessonPagesNeedingToBeUpdated.forEach((el) => {
      if (!arrayOfUniqueOrders.includes(el.order)) {
        arrayOfUniqueOrders.push(el.order);
      } else {
        alarm = true;
      }
    });
    lessonPagesAddedByClient.forEach((el, index) => {
      // Clone the pageManifest to avoid modifying the original
      let clonedPageManifest = _.cloneDeep(el.pageManifest);

      // Clean the cloned pageManifest (this removes the moduleRef property)
      clonedPageManifest = cleanPageManifest(clonedPageManifest);

      // Create a new object with the cleaned pageManifest
      lessonPagesAddedByClient[index] = {
        ...el,
        pageManifest: clonedPageManifest,
      };

      if (!arrayOfUniqueOrders.includes(el.order)) {
        arrayOfUniqueOrders.push(el.order);
      } else {
        alarm = true;
      }
    });

    deletedLessonPages.forEach((el: number) => {
      if (el === -1) {
        alarm = true;
      }
    });

    const lessonMetaCopy = _.cloneDeep(lessonMetaData);
    if (lessonMetaCopy.aircraftId) {
      lessonMetaCopy.manufacturerId = null;
      lessonMetaCopy.aircraftFamilyId = null;
    } else if (lessonMetaCopy.aircraftFamilyId) {
      lessonMetaCopy.manufacturerId = null;
    }

    const copiedAssets: ILessonAsset[] = assignAssetsToCopiedPage(
      copyPageResponse.data.assetVersionIds,
      copyPageResponse.data.pageVersionId,
    );
    // let toUpdate: any = _.filter(newLessonPages, page => {return page.order >= pageOrder})

    const cpObject = {
      pageAssetsCurrent: crawl(newLessonPages),
      lessonPagesToAdd: lessonPagesAddedByClient,
      lessonPagesToDelete: deletedLessonPages,
      lessonPagesToUpdate: lessonPagesNeedingToBeUpdated,
      pageManifestsToUpdate: pageManifestsNeedingToBeUpdated,
      lessonAssetsToAdd: [...lessonAssetList, ...copiedAssets].filter((asset) => !!asset.assetVersionId),
      lessonAssetsToRemove: lessonAssetsNeedingToBeDeleted,
      lessonMeta: lessonMetaCopy,
    };
    newLessonPages = referenceIdLogic(newLessonPages);
    const copyRes: IApiResponse<IUpdateLessonResponse> = await genericRepositoryService.updateLesson(cpObject);
    handleApiError(copyRes, "failed to save");

    if (copyRes.isSuccess && alarm === false) {
      const info = copyRes.data.addLessonPageResponses;
      if (info) {
        const givePageId = () => {
          for (let i = 0; i < info.length; i++) {
            newLessonPages[info[i].order].lessonPageId = info[i].lessonPageId;
          }
        };
        givePageId();
      }
      const meta: any = _.cloneDeep(copyRes.data.lessonMeta);
      if (meta.aircraftId) {
        meta.aircraftFamilyId = null;
        meta.manufacturerId = null;
      }

      newLessonPages = newLessonPages.map((page: ILessonPageDto, index: number) => {
        if ("isClientSideNewPage" in page) {
          page.isClientSideNewPage = false;
        }
        if ("lessonPageIsDirty" in page) {
          page.lessonPageIsDirty = false;
        }
        if ("pageManifestIsDirty" in page) {
          page.pageManifestIsDirty = false;
        }
        return page;
      });
      setLessonPages(newLessonPages);
      setCurrentlyDisplayedPageIndex(pageOrder);
      setLessonAssetList([]);
      setLessonAssetsNeedingToBeDeleted([]);
      setDeletedLessonPages([]);
      setLessonMetaData(meta);
    }
    document.body.classList.remove("remove-pointer-events");
  };
  const handleAddingFMS = async (pageVersionId: number, pageManifest: IFmsPageManifest) => {
    // let aircraftMode = prepareAircraftMode(lessonMetaData);
    // let fmsCheck = currentFMSObject && currentFMSObject.assetVersionId && currentFMSObject.assetVersionId > 0 && pageType == "FMS"

    if (
      currentFMSObject.cdu.length === 0 ||
      currentFMSObject.cduPath.length === 0 ||
      currentFMSObject.assetVersionId < 0
    ) {
      // pageManifest.cdu = fmsCdus
    } else {
      pageManifest.cdu = currentFMSObject.cdu;
      pageManifest.cduPath = `${currentFMSObject.cduPath}/`;
      pageManifest.assetVersionId = currentFMSObject.assetVersionId;
    }
    pageManifest.version = checkPageTypeForVersion("FMS", lessonPages);
    updatePageAsset(currentFMSObject.assetVersionId, null, pageVersionId);
    //add version here
    return pageManifest;
  };

  const assignAssetsToNewPage = (assetVersionIds: number[], pageVersionId: number) => {
    //asset version IDs are the ids of newly created pages
    const newLessonAssets: ILessonAsset[] = _.cloneDeep(lessonAssetList);
    if (!_.isEmpty(assetVersionIds)) {
      _.forEach(assetVersionIds, (assetVersion) => {
        const tempAssets: ILessonAsset = {
          assetVersionId: assetVersion,
          pageVersionId: pageVersionId,
          lessonVersionId: lessonVersionId,
        };
        newLessonAssets.push(tempAssets);
      });
      return newLessonAssets;
    }
    return newLessonAssets;
  };

  const assignAssetsToCopiedPage = (assetVersionIds: number[], pageVersionId: number) => {
    const tempAssetList: ILessonAsset[] = [];
    _.forEach(pageAssetIds, (id) => {
      //in here we should be pushing all of the assets from the page that was being copied.
      if (id !== 0) {
        tempAssetList.push({
          assetVersionId: typeof id === "string" ? parseInt(id) : id,
          lessonVersionId: lessonVersionId,
          pageVersionId: pageVersionId,
        });
      }
    });
    // setLessonAssetList(updatedAssetsToAdd);
    return tempAssetList;
  };

  const setSelectorModalInfo = (actionName: string) => {
    setIsModalShown(true);
    setModalType(actionName);
  };
  const updateHelperReference = (referenceId: number) => {
    const newLessonPages: ILessonPageDto[] = _.cloneDeep<ILessonPageDto[]>(lessonPages);
    newLessonPages[currentlyDisplayedPageIndex].helperReferencePage = +referenceId;
    newLessonPages[currentlyDisplayedPageIndex].lessonPageIsDirty = true;

    setLessonPages((lp) => referenceIdLogic(newLessonPages, lp));
  };

  const createAssetManagementContextProvider = (): IAssetManagementContext => {
    return {
      assetIndex: assetIndex,
      assetTypeId: assetTypeId,
      setAssetIndex,
      setAssetTypeId,
      showBlobUploader,
      aircraftId: currentAircraftId,
      lessonDataSettings,
      lessonMetaData,
      setAssetManagerController,
    };
  };

  const createPageManifestContextProvider = (): IPageContext => {
    if (_.isArray(lessonPages) && currentlyDisplayedPageIndex < lessonPages.length) {
      // copy to guard against referential manipulation of state and context

      const lessonPageMutabilityGuard: ILessonPageDto = _.cloneDeep<ILessonPageDto>(
        lessonPages[currentlyDisplayedPageIndex],
      );
      return {
        helperReference: lessonPageMutabilityGuard.helperReferencePage,
        currentlyDisplayedPageIndex: currentlyDisplayedPageIndex,
        page: lessonPageMutabilityGuard,
        pageAssetIds: pageAssetIds,
        pageManifest: lessonPageMutabilityGuard.pageManifest,
        pageType: lessonPageMutabilityGuard.pagePlayerType,
        pageId: lessonPageMutabilityGuard.pageVersionId,
        isThereA3DPage,
        isAnnotationsShown,
        addNewPageAndDisplay,
        // compareClientSideAsset,
        handleApiError: handleApiError,
        setPageAssetIds,
        setProceduresSelectorBoolean,
        setSelectorModalInfo,
        updateAllPagesTheme,
        showAdvancedEditor: setShowAdvancedEditor,
        showLiveryEditor: setShowLiveryEditor,
        updateHelperReference,
        updatePageManifest,
        updatePageAsset,
        getObjectIncrementor,
        doesObjectIncrementorExist,
        fmsDataWorksheetURL: fmsDataWorksheetURL,
        // addAssetToCurrentLessonPage,
        // removeAssetFromCurrentLessonPage,
      };
    }

    // else send in blank information
    return {
      helperReference: 0,
      currentlyDisplayedPageIndex: 0,
      pageAssetIds: [],
      page: {},
      pageType: "",
      pageManifest: null,
      pageId: 0,
      isThereA3DPage,
      isAnnotationsShown,
      addNewPageAndDisplay,
      handleApiError: handleApiError,
      setSelectorModalInfo,
      setPageAssetIds,
      setProceduresSelectorBoolean,
      showAdvancedEditor: setShowAdvancedEditor,
      showLiveryEditor: setShowLiveryEditor,
      updateHelperReference,
      updatePageAsset,
      updatePageManifest,
      getObjectIncrementor,
      doesObjectIncrementorExist,
      fmsDataWorksheetURL: fmsDataWorksheetURL,
      updateAllPagesTheme,
    };
  };

  const createLessonAdditionalSettingsContext = (): ILessonAdditionalSettingsContext => {
    return {
      settings: lessonMetaData.additionalSettings,
      getColorsList: (standardColors: string[]): string[] => {
        // merge the incoming and customized colors from lesson list
        const additionalColors: string[] | undefined =
          lessonMetaData?.additionalSettings?.clientCustomizations?.additionalColors;
        const currentColors = standardColors;
        _.forEach(additionalColors, (color, index) => {
          if (!currentColors.includes(color) && null != color) {
            currentColors.push(color);
          }
        });
        return currentColors;
      },
    };
  };

  const createAddPageContextProvider = (): ILessonManagementContext => {
    return {
      assetManagerController,
      currentlyDisplayedPageIndex,
      errorMessage,
      fmsObject: {},
      isSaved,
      lessonPages,
      proceduresObject: {},
      timeSpent,
      addNewPageAndDisplay,
      deletePage,
      pageIndexIsSafeToDelete,
      saveLesson,
      setSelectorModalInfo,
      toggleLessonDataModal,
      handleApiError: handleApiError,
      setLessonMetaData,
      setAssetManagerController,
      copyPage,
    };
  };
  const createPagesContextProvider = (): IPagesManagementContext => {
    return {
      lessonPages: /*lessonPagesMutabilityGuard*/ lessonPages,
      changePageOrder: changePageOrder,
      switchCurrentlyDisplayedPage,
      pagePullout: pagePullout,
      currentlyDisplayedPageIndex,
      lessonModes: lessonModes,
    };
  };
  const createEditorContextProvider = (): IEditorModeContext => {
    const lessonPageMutabilityGuard: ILessonPageDto = _.cloneDeep<ILessonPageDto>(
      lessonPages[currentlyDisplayedPageIndex],
    );
    if (lessonPageMutabilityGuard?.lessonModeIds) {
      return {
        lessonModeIds: lessonPageMutabilityGuard.lessonModeIds,
        selectedEditorLessonModes: selectedEditorLessonModes,
        setSelectedEditorLessonModes,
        lessonModes: lessonModes,
        pageType: lessonPageMutabilityGuard.pagePlayerType,
      };
    } else {
      return {
        lessonModeIds: [],
        selectedEditorLessonModes: selectedEditorLessonModes,
        setSelectedEditorLessonModes,
        lessonModes: lessonModes,
        pageType: "",
      };
    }
  };

  const setLessonPageActions = (newLessonPageActions: ILessonPageAction[]): void => {
    setActionButtons(newLessonPageActions);
  };

  const handleRightSide = (action: string, iDpayload?: number[]): void => {
    const lp = _.cloneDeep(lessonPages);
    if (!iDpayload) {
      switch (action) {
        case "shouldAutoPageForward":
          lp[currentlyDisplayedPageIndex].shouldAutoPageForward =
            !lp[currentlyDisplayedPageIndex].shouldAutoPageForward;
          lp[currentlyDisplayedPageIndex].lessonPageIsDirty = true;
          changePageOrder(lp);
          break;
        case "isScorable":
          lp[currentlyDisplayedPageIndex].isScorable = !lp[currentlyDisplayedPageIndex].isScorable;
          lp[currentlyDisplayedPageIndex].lessonPageIsDirty = true;
          changePageOrder(lp);
          break;
      }
    } else {
      log("iDpayload", { iDpayload });
      lp[currentlyDisplayedPageIndex].lessonModeIds = iDpayload;
      //
      lp[currentlyDisplayedPageIndex].lessonPageIsDirty = true;
      changePageOrder(lp);
    }
  };
  const initialLessonPageActionsContext = (): ILessonPageActionsContext => {
    return {
      assetManagerController,
      deleteWarning: deletePagesWarning,
      dirt,
      dirtyFmsObject: dirtyFmsObject,
      fmsCdus,
      fmsObject: currentFMSObject,
      isAnnotationsShown: isAnnotationsShown,
      lessonModes: lessonModes,
      lessonPageActions: actionButtons,
      previewMode,
      proceduresObject: current3DObject,
      selectedEditorLessonModes: selectedEditorLessonModes,
      setDirt,
      setPreviewMode,
      threeDModels,
      handleRightSide,
      setAssetManagerController,
      setCurrent3DObject: setCurrent3DObject,
      setDeleteWarning: setDeletePagesWarning,
      setFmsObject: setCurrentFMSObject,
      setIsAnnotationsShown,
      setLessonPageActions: setLessonPageActions,
      setLessonSettingsController,
      setPageTypeToDelete: setPageTypeToDelete,
      setSelectedEditorLessonModes,
      setWalkaroundSelectorShowing,
      showFMSSelector,
      currentWalkAroundObject,
      walkaroundModels,
      setCabinSelectorShowing,
      currentCabinObject,
      cabinModels,
    };
  };

  // this is dead code, warrants cleanup
  const toggleLessonDataModal = (): void => {
    setLessonDataModalToOn(!lessonDataModalIsOn);
  };
  const permeateLessonName = (name: string): void => {
    const meta = _.cloneDeep(lessonMetaData);
    meta.lessonName = name;
    if (name.length <= 200) {
      setLessonName(name);
      setLessonMetaData(meta);
    }
  };

  async function lessonDataPopulate(metaData: any) {
    async function copyrightOwnersPopulate() {
      const response: IApiResponse<IGetNewLessonDependenciesResponse> =
        await genericRepositoryService.getNewLessonInformation();
      handleApiError(response);
      return [...response.data.lmss];
    }

    async function aircraftDataPopulate(): Promise<any> {
      try {
        const response: IApiResponse<IGetAircraftLessonData> = await genericRepositoryService.getAircraftLessonData();
        handleApiError(response);
        setAllAvailableAircraft(response.data);
        return response.data;
      } catch (e) {
        console.error(e);
      }
      const response = { data: [] };
      return response.data;
    }
    async function ataDataPopulate(): Promise<any> {
      const response: IApiResponse<IGetATAData> = await genericRepositoryService.getATAData();
      handleApiError(response);
      return response.data;
    }
    async function lessonTypesPopulate() {
      const response: IApiResponse<IGetLessonTypes> = await genericRepositoryService.getLessonTypes();
      handleApiError(response);
      return response.data;
    }

    async function lessonStylesPopulate() {
      const response: IApiResponse<IGetLessonStyle> = await genericRepositoryService.getLessonStyle();
      handleApiError(response);
      return response.data;
    }
    const aircrafts = await aircraftDataPopulate();
    setLessonDataSettings({
      aircrafts: aircrafts,
      ata: await ataDataPopulate(),
      lessonMetaData: metaData,
      lessonTypes: await lessonTypesPopulate(),
      lessonStyles: await lessonStylesPopulate(),
      lmss: await copyrightOwnersPopulate(),
    });
  }

  function setFMSandProcedures(lp: any = []) {
    if (showAnnotationAlert || isShowStoperShown) {
      if (currentFMSObject.cdu === "no3DorFms") {
        setCurrentFMSObject(lookupFMSorProceduresObject(lp, "FMS") as IFMSObject);
      }
    } else {
      setCurrentFMSObject(lookupFMSorProceduresObject(lp, "FMS") as IFMSObject);
    }
    // setCurrentFMSObject(lookupFMSorProceduresObject(lp, "FMS") as IFMSObject);
    setCurrent3DObject(lookupFMSorProceduresObject(lp, "ThreeDModel") as IProceduresObject);
    setCurrentWalkAroundObject(lookupFMSorProceduresObject(lp, "Walkaround") as WalkAroundObject);
    setCurrentCabinObject(lookupFMSorProceduresObject(lp, "Cabin") as CabinObject);
  }

  function lookupFMSorProceduresObject(
    lp: ILessonPageDto[],
    flag: "FMS" | "ThreeDModel" | "Walkaround" | "Cabin",
  ): IProceduresObject | IFMSObject | WalkAroundObject | CabinObject {
    const x = lp.filter((x) => {
      return x.pagePlayerType === flag;
    });
    if (x.length > 0) {
      // let first3d = x.map(({ pageManifest }, index, array) => {
      switch (flag) {
        case "FMS":
          return {
            cdu: x[0].pageManifest.cdu,
            cduPath: x[0].pageManifest.cduPath,
            assetVersionId: x[0].pageManifest.assetVersionId,
          } as IFMSObject;
        case "ThreeDModel":
          return {
            modelSet: x[0].pageManifest.modelSet.modelSet,
            assetVersionId: x[0].pageManifest.modelSet.assetVersionId,
            role: x[0].pageManifest.role,
          } as IProceduresObject;
        case "Walkaround":
          return {
            modelSet: x[0].pageManifest.modelSet.modelSet,
            assetVersionId: x[0].pageManifest.modelSet.assetVersionId,
            role: x[0].pageManifest.role,
          };
        case "Cabin":
          return {
            modelSet: x[0].pageManifest.modelSet.modelSet,
            assetVersionId: x[0].pageManifest.modelSet.assetVersionId,
            role: x[0].pageManifest.role,
          };
      }
      // })[0];
      // log('FIRST 3D', {first3d})
      // return first3d;
    } else {
      switch (flag) {
        case "FMS":
          return {
            cdu: "no3DorFms",
            cduPath: "no3DorFms",
            assetVersionId: -1,
          } as IFMSObject;

        case "ThreeDModel":
          return {
            modelSet: "no3DorFms",
            assetVersionId: undefined,
            role: "Captain",
          } as IProceduresObject;

        case "Walkaround":
          return {
            modelSet: "noWalkAround",
            assetVersionId: undefined,
            role: "Captain",
          };
        case "Cabin":
          return {
            modelSet: "noCabin",
            assetVersionId: undefined,
            role: "Captain",
          };
      }
    }
  }
  function removeANumber(value: number, array: number[]) {
    for (let i = array.length; i--; ) {
      if (array[i] === value) {
        array.splice(i, 1);
      }
    }
    return array;
  }

  function deletePageType(pageType: string) {
    const deletedPages = lessonPages
      .filter((page) => {
        return page.pagePlayerType === pageType && page.pageVersionId !== -1;
      })
      .map((el) => {
        return el.lessonPageId;
      });
    const newPages = lessonPages.filter((x, i) => {
      if (i > 3) {
        x.lessonPageIsDirty = true;
      }
      return x.pagePlayerType !== pageType;
    });

    // log('delete pagetypes', { deletedPages, newPages })
    setLessonPages((lp) => referenceIdLogic(newPages, lp));
    setDeletedLessonPages(removeANumber(-1, deletedPages));
    setCurrentlyDisplayedPageIndex(4);
  }

  const deletePageSequence = (pageType: string) => {
    deletePageType(pageType);
  };

  useEffect(() => {
    if (lessonMetaData.aircraftId !== -1) {
      const aircraftMode = prepareAircraftMode(lessonMetaData);
      downloadThreeDModels(aircraftMode);
      downloadFmsModels(aircraftMode);
      downloadWalkaroundModels(aircraftMode);
      downloadCabinModels(aircraftMode);
    }
  }, [lessonMetaData.manufacturerId, lessonMetaData.aircraftFamilyId, lessonMetaData.aircraftId]);

  const getInitial3D = () => {
    if (lessonPages.find((x) => x.pagePlayerType === "ThreeDModel")) {
      const lp = _.cloneDeep(lessonPages);
      lp.forEach((el) => {
        if (el.pagePlayerType === "ThreeDModel" && current3DObject.assetVersionId) {
          el.pageManifest.modelSet.modelSet = current3DObject.modelSet;
          el.pageManifest.modelSet.assetVersionId = current3DObject.assetVersionId.toString();
          el.pageManifest.role = current3DObject.role;
          el.pageManifestIsDirty = true;
        }
      });
      setLessonPages((olp) => referenceIdLogic(lp, olp));
      setIsThereA3DPage(true);
    }
  };
  // handles http request to mark that the user close a particular lesson
  const closeLesson = async (e: Event) => {
    e.preventDefault();
    await genericRepositoryService.closeLessonWithBeacon({
      lessonVersionId: lessonVersionId,
    });
  };

  useEffect(() => {
    window.onunload = closeLesson;
    window.addEventListener("unload", closeLesson);
    return () => {
      window.removeEventListener("unload", closeLesson);
      window.onunload = null;
    };
  }, [closeLesson]);

  useEffect(() => {
    setIsAnnotationsShown(true);
  }, [currentlyDisplayedPageIndex]);

  useEffect(() => {
    setLessonMetaData({
      ...lessonMetaData,
      featuresToRender: featuresToRender,
    } as any);
  }, [featuresToRender]);

  useEffect(() => {
    if (props.lessonId) {
      //Should Edit Lesson if this information is populated
      openLesson();
    }
  }, []);

  const sliderSpring = useSpring({
    config: config.default,
    width: pagePullout ? 622 : 304,
  });

  const assetManagerProps = {
    lessonData: lessonDataSettings, // TODO... this seems like a hacky way to get this inbound...
    assetManagerController,
    setAssetManagerController,
    allAvailableAircraft,
    lessonMetaData,
    lessonPages,
    lessonAssetList,
    lessonVersionId,
    featuresToRender,
  };

  const lessonSettingsProps = {
    lessonSettingsController,
    setLessonSettingsController,
    lessonMetaData,
    setLessonMetaData,
    setAssetManagerController,
    saveLesson,
    fmsCdus,
    threeDModels,
    lessonPages,
  };

  const headerProps = {
    setLessonSettingsController,
    setCoursewareDeveloperToolsController,
    setAssetTypeId,
  };

  const walkAroundSelectorProps = {
    walkaroundSelectorShowing,
    setWalkaroundSelectorShowing,
    setCurrentWalkAroundObject,
    walkaroundModels,
    addNewPageAndDisplay,
    currentlyDisplayedPageIndex,
  };

  const cabinSelectorProps = {
    cabinSelectorShowing,
    setCabinSelectorShowing,
    setCurrentCabinObject,
    cabinModels,
    addNewPageAndDisplay,
    currentlyDisplayedPageIndex,
  };

  const aircraftLiveryModalProps = {
    show: showLiveryEditor,
    lessonMetaData: lessonMetaData,
    currentlyDisplayedPageIndex: currentlyDisplayedPageIndex,
    pages: lessonPages,
    saveLesson: saveLesson,
  };

  const showLiveryButton = () => {
    if (isCPaTUser || updatelivery) {
      return <SelectAircraftLiveryModal {...aircraftLiveryModalProps} />;
    }
  };

  const hasTimeline = isTimelineEnabled(lessonPagesState.currentLessonPage);

  return (
    <div id="containerForApp">
      <FailedToSave failedToSave={failedToSave} setFailedToSave={setFailedToSave} saveLesson={saveLesson} />
      <RefreshModal history={props.history} show={refreshModal} />
      {isShowStoperShown ? (
        <StopOpenLesson lessonMeta={lessonMetaData} lessonPages={lessonPages} resumeOpenLesson={resumeOpenLesson} />
      ) : (
        <></>
      )}
      {showAnnotationAlert ? (
        <StopOpenLesson
          lessonMeta={lessonMetaData}
          lessonPages={lessonPages}
          resumeOpenLesson={resumeOpenLesson}
          annotationUpdate={showAnnotationAlert}
        />
      ) : (
        <></>
      )}
      {/* <RetryApiModal reProcessFunction={openLesson} showModal={pageLoadDidFail} /> */}
      {!isSaved && (
        <Portal>
          <div className="save-blocking-screen">
            <div>
              <div className="loader-save">Saving..</div>
            </div>
          </div>
        </Portal>
      )}
      <DeleteConfirmation
        deletePageConfirmationModal={deletePageConfirmationModal}
        setDeletePageConfirmationModal={setDeletePageConfirmationModal}
      />
      <LessonSettingsProvider lessonMetaData={lessonSettingsProps.lessonMetaData}>
        <LessonAdditionalSettingsContext.Provider value={createLessonAdditionalSettingsContext()}>
          <LessonPageActionsContext.Provider value={initialLessonPageActionsContext()}>
            <EditorModeContext.Provider value={createEditorContextProvider()}>
              <div className="header-area">
                <LessonManagementContext.Provider value={createAddPageContextProvider()}>
                  <DesignerHeader
                    {...headerProps}
                    lessonName={lessonName}
                    lmsName={lessonMetaData.lmsName}
                    lessonVersionId={lessonVersionId}
                    lessonVersionDisplayableId={displayID}
                    changePageOrder={changePageOrder}
                  />
                </LessonManagementContext.Provider>
              </div>
              <LessonSize lessonMetaData={lessonMetaData} />
              <div id="containerForContentArea" className="">
                <animated.div className=" sidebar-background" style={sliderSpring}>
                  <PagesManagementContext.Provider value={createPagesContextProvider()}>
                    <PageLister />
                    <button className="open-page-lister" onClick={() => togglePagePullout(!pagePullout)}>
                      {drawerIcon(pagePullout)}
                    </button>
                  </PagesManagementContext.Provider>
                </animated.div>

                <PageContext.Provider value={createPageManifestContextProvider()}>
                  <AssetContext.Provider value={createAssetManagementContextProvider()}>
                    <TablesDataProvider>
                      {/* <TablesDataProvider> */}

                      {/*<AssetUploaderModal showModal={blobUploader} />*/}
                      {allAvailableAircraft && <AssetManager {...assetManagerProps} />}
                      {lessonMetaData.additionalSettings?.forceAssetMetaCompletion && isSaved && (
                        <MetadataEditor lessonMeta={lessonMetaData} showModal={false} />
                      )}
                      <LessonSettings {...lessonSettingsProps} />
                      <DesignerModal
                        showModal={isModalShown}
                        modalType={modalType}
                        hideModal={() => setIsModalShown(false)}
                      />
                      <DeletePagesWarningModal
                        pageType={pageTypeToDelete}
                        deletePageSequence={deletePageSequence}
                        setDeletePagesWarning={setDeletePagesWarning}
                        setFmsObject={setCurrentFMSObject}
                        set3DObject={setCurrent3DObject}
                      />
                      <SelectFmsModal
                        setCurrentFMSObject={setCurrentFMSObject}
                        pageNumber={currentlyDisplayedPageIndex}
                        showModal={isFMSSelectorShown}
                        setIsFMSSelectorShown={setIsFMSSelectorShown}
                      />
                      {lessonMetaData.aircraftId && proceduresSelectorBoolean && (
                        <SelectProceduresModal
                          allAvailableAircraft={allAvailableAircraft}
                          deletePageSequence={deletePageSequence}
                          showModal={proceduresSelectorBoolean}
                          setShowModal={setProceduresSelectorBoolean}
                          lessonMetaData={lessonMetaData}
                          setLessonMetaData={setLessonMetaData}
                        />
                      )}
                      <SelectWalkAroundModal {...walkAroundSelectorProps} />
                      <SelectCabinModal {...cabinSelectorProps} />

                      <AdvancedEditor show={showAdvancedEditor} />
                      {showLiveryButton()}
                      <WysiwygProvider>
                        <div className="pagePlayerBox no-padding">
                          <div className="pagePlayerInnerBox">
                            <img src={backgroundLogo} alt="CPAT Logo" id="background-logo" />
                            <PageEditor
                              isSaved={isSavedLoaded}
                              pageIndex={currentlyDisplayedPageIndex}
                              lessonMetaData={lessonMetaData}
                              setLessonMetaData={setLessonMetaData}
                              lessonName={lessonName}
                              permeateLessonName={permeateLessonName}
                              assetManagerProps={assetManagerProps}
                            />
                          </div>
                        </div>
                      </WysiwygProvider>
                      <RightPanel
                        lessonPages={lessonPages}
                        currentlyDisplayedPageIndex={currentlyDisplayedPageIndex}
                        lessonVersionId={lessonVersionId}
                        isModalShown={isModalShown}
                      />
                      <DrawerContainer />
                      {!hasTimeline && (
                        <NarratorDrawer
                          selectedPage={currentlyDisplayedPageIndex}
                          page={lessonPages.length > 0 && lessonPages[currentlyDisplayedPageIndex]}
                          updatePageManifest={updatePageManifest}
                          lessonMetaData={lessonMetaData}
                        />
                      )}
                    </TablesDataProvider>
                  </AssetContext.Provider>
                </PageContext.Provider>
              </div>
            </EditorModeContext.Provider>
          </LessonPageActionsContext.Provider>
        </LessonAdditionalSettingsContext.Provider>
      </LessonSettingsProvider>
    </div>
  );
};

export default Builder;
