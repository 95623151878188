import React, { useState } from "react";
import "./AlternativeNarrationModal.css";
import { BaseModal } from "../BaseModal";
import { ModalSize } from "../BaseModal/types";
import { useLessonSettingsDispatch, useLessonSettingsState } from "../../LessonSettings/LessonSettingsContext/LessonSettingsProvider";

interface AlternativeNarrationModalProps {
  open: boolean;
  onClose: () => void;
  onFinish: (values: { language: string }) => void;
}

export const AlternativeNarrationModal: React.FC<AlternativeNarrationModalProps> = ({
  open,
  onClose,
  onFinish,
}) => {
  const [language, setLanguage] = useState<string>("");
  const settingsState = useLessonSettingsState();
  const settingsDispatch = useLessonSettingsDispatch();


  const handleSubmit = () => {
    if (!language.trim()) {
      alert("Please provide a valid language.");
      return;
    }
    
    settingsDispatch({
      type: "UPDATE_SECONDARY_LANGUAGE",
      payload: language,
    });
    onFinish({ language });

    setLanguage("");
    onClose();
  };

  const handleCancel = () => {
    setLanguage("");
    onClose();
  };

  return (
    <BaseModal size={ModalSize.SM} open={open} title="Alternative Narration">
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <div className="alternative-narration-form-wrapper">
          <p className="alternative-narration-instruction">
            You are adding an alternative narration. Please specify for what
            alternative language this is.
          </p>

          <div className="form-section">
            <label className="form-label">Alternative Language:</label>
            <div className="form-field">
              <input
                type="text"
                placeholder="Enter alternative language"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="cpat-text-input"
              />
            </div>
          </div>

          <div className="form-actions">
            <button
              type="button"
              className="cpat-button cpat-button--label cpat-button--reverse"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button type="submit" className="cpat-button cpat-button--label">
              Save
            </button>
          </div>
        </div>
      </form>
    </BaseModal>
  );
};
