import React, { useState, useContext, ChangeEvent, Dispatch, SetStateAction } from "react";
import "./ObjectPropertiesPanel.css";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";
import { ReactComponent as FrameSVG } from "../TimelinePanel/Right/ObjectRow/Frames/FilledKeyframe.svg";
import { ReactComponent as ArrowIcon } from "../../../src/assets/icons/HeaderIcons/drop-down.svg";
// "../../../assets/icons/HeaderIcons/drop-down.svg";
import { ReactComponent as NoObject } from "./NoObjectSelected.svg";
import {
  SelectedObjectActionTypes,
  useSelectedObjectDispatch,
  useSelectedObjectState,
} from "../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { BaseObject, HotspotObject } from "../../types";
import { snapToGrid, useTimeline } from "../../contexts/TimelineProvider/TimelineProvider";
import { symbolTypes, annotationTypes, fontAnnotation, OBJECT_TYPE_SHAPE } from "../../const";
import { valueToPercentage, conversion } from "../../utils/Conversion";
import { useMovableElementsPlaneState } from "../../contexts/MovableElementsPlaneProvider";
import EditImageMenu from "../../components/ObjectPropertyBox/components/EditImageMenu";
import ColorInput from "../../components/ObjectPropertyBox/components/ColorInput";
import { useInteracitvityHotspotState } from "../../contexts/InteractivityHotspotProvider";

import { useTablesDataState } from "../../components/Tables/TablesDataProvider";
import { PageContext } from "../../routes/builderContexts";
import { isInTimeline } from "../../utils/PageManifestParsing";
import { simpleDebounce } from "../../utils";
import PBMenuHeader from "../../components/ObjectPropertyBox/components/PBMenuHeader";
import HotspotOptions from "../../components/ObjectPropertyBox/components/PageCompletionHeader";
import { ClickGroup } from "../../components/ObjectPropertyBox/components/interactivityhotspots/ClickGroup";
import { useObjectIsInTime } from "../../hooks/useObjectIsInTime";
import { useAnimatedObject } from "../../hooks/useAnimatedObject";
// import ZoomingAndCroppingMenu from "../../components/ObjectPropertyBox/components/ZoomingCroppingMenu";
const debounce = simpleDebounce(750);

export interface IPropertyBoxPanel {
  isModalShown: boolean;
}

export default function PropertyBoxPanel({ isModalShown }: IPropertyBoxPanel) {
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const selectedObjectState = useSelectedObjectState();
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const pageContex = useContext(PageContext);
  const [tl] = useTimeline();
  const { viewportDOMElementWidth, viewportDOMElementHeight } = useMovableElementsPlaneState();
  const currentTime = tl?.scrubbingCurrentTime;
  const selectedObject: BaseObject | undefined = objectsState.selectedObjects[0];
  const [showCroppingMenu, setShowCroppingMenu] = useState(false);
  const [showColorsMenu, setShowColorsMenu] = useState(false);
  const interactivityHotspotState = useInteracitvityHotspotState();
  const DIRECTIONS = {
    TOP: "top",
    BOTTOM: "bottom",
    LEFT: "left",
    RIGHT: "right",
    TOP_LEFT: "top-left",
    TOP_RIGHT: "top-right",
    BOTTOM_LEFT: "bottom-left",
    BOTTOM_RIGHT: "bottom-right",
  };
  const x = selectedObjectState?.x?.toFixed(0) ?? 0;
  const y = selectedObjectState?.y?.toFixed(0) ?? 0;
  const width = selectedObjectState?.width?.toFixed(0) ?? 0;
  const height = selectedObjectState?.height?.toFixed(0) ?? 0;
  const [localW, setWidth] = useState<string | null>(null);
  const [localH, setHeight] = useState<string | null>(null);
  const rotation = selectedObjectState?.rotation?.toFixed(0) ?? 0;
  const opacity = selectedObjectState?.opacity ?? 1;
  const lockAspectRatio = selectedObject?.lockAspectRatio ?? false;
  const visible = selectedObject?.isDisplayed ?? false;
  const zIndex = selectedObject?.zIndex?.toFixed(0) ?? 0;

  const animatedObject = useAnimatedObject(selectedObject?.objectId);
  const frameAtCurrentTime = animatedObject?.frames?.find((frame) => frame.timestamp === currentTime);
  const selectedIsInTimeline = useObjectIsInTime(selectedObject?.objectId);
  const masterFrameDisable = selectedIsInTimeline ? false : true;
  if (
    objectsState.selectedObjects.length > 1 ||
    (!selectedObject && !interactivityHotspotState.currentlySelectedHotspot)
  ) {
    return (
      <div
        className="property-box-panel"
        style={{
          display: "flex",
          height: "55%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <NoObject />
        {!!objectsState.objectList.length && <p>Select an object</p>}
        {!!objectsState.objectList.length && (
          <select
            value={""}
            placeholder="Select an object"
            onChange={(e) => {
              objectsDispatch({
                type: ObjectActionsType.SET_SELECTED_OBJECT,
                payload: {
                  objectId: e.target.value,
                },
              });
            }}
          >
            <option disabled value="">
              select an object
            </option>
            {[...objectsState.objectList]
              .sort((a, b) => {
                const aDisplay = (a?.displayName ?? "").toUpperCase();
                const bDisplay = (b?.displayName ?? "").toUpperCase();
                if (aDisplay < bDisplay) {
                  return -1;
                }
                if (aDisplay > bDisplay) {
                  return 1;
                }
                return 0;
              })
              .map((object) => (
                <option key={object.objectId} value={object.objectId}>
                  {object.displayName}
                </option>
              ))}
          </select>
        )}
      </div>
    );
  }
  const xHovered = typeof frameAtCurrentTime?.x === "number";
  const yHovered = typeof frameAtCurrentTime?.y === "number";
  const widthHovered = typeof frameAtCurrentTime?.width === "number";
  const heightHovered = typeof frameAtCurrentTime?.height === "number";
  const rotationHovered = typeof frameAtCurrentTime?.rotation === "number";
  const opacityHovered = typeof frameAtCurrentTime?.opacity === "number";

  const offScreen = selectedObjectState?.offScreen;
  const displayName = objectsState?.selectedObjects[0]?.displayName ?? "";
  objectsState?.selectedObjects[0]?.name ?? "";
  const path =
    objectsState?.selectedObjects[0]?.path ??
    objectsState.selectedObjects[0]?.imagePath ??
    objectsState?.selectedObjects[0]?.blobPath ??
    objectsState?.selectedObjects[0]?.assetBlobPath ??
    "";
  const canEditHeight =
    objectsState?.selectedObjects[0]?.type?.toUpperCase().includes("LINE") ||
    objectsState?.selectedObjects[0]?.type?.toUpperCase().includes("ARROW")
      ? false
      : true;
  const canEditLockRatio =
    objectsState.selectedObjects[0]?.type === "freeFormPoly" ||
    symbolTypes.has(objectsState.selectedObjects[0]?.type) ||
    objectsState.selectedObjects[0]?.type?.toUpperCase().includes("VIDEO") ||
    objectsState.selectedObjects[0]?.type?.toUpperCase().includes("SCORM") ||
    fontAnnotation.has(objectsState.selectedObjects[0]?.type) ||
    objectsState.selectedObjects[0]?.type === "label" ||
    objectsState.selectedObjects[0]?.type === "textBlock" ||
    objectsState.selectedObjects[0]?.type === "smartObject" ||
    objectsState.selectedObjects[0]?.type?.toUpperCase().includes("LINE") ||
    objectsState.selectedObjects[0]?.type?.toUpperCase().includes("ARROW")
      ? false
      : true;
  const canEditOpacity =
    annotationTypes.has(objectsState?.selectedObjects[0]?.type) ||
    symbolTypes.has(objectsState?.selectedObjects[0]?.type) ||
    objectsState?.selectedObjects[0]?.type === "smartObject" ||
    objectsState?.selectedObjects[0]?.type === "pageImage" ||
    objectsState?.selectedObjects[0]?.type === OBJECT_TYPE_SHAPE ||
    objectsState?.selectedObjects[0]?.type === "label"
      ? true
      : false;
  const canEditRotation =
    objectsState?.selectedObjects[0]?.type !== "SCORM" &&
    objectsState?.selectedObjects[0]?.type !== "video" &&
    objectsState?.selectedObjects[0]?.type !== "table" &&
    objectsState?.selectedObjects[0]?.type !== "panoramic"
      ? true
      : false;

  const handleWidthChange = (value) => {
    setWidth(value);
    debounce(() => {
      const newWidth = parseInt(value);
      const deltaWidth = newWidth - selectedObjectState.width;
      const keepRatio = objectsState.selectedObjects[0].lockAspectRatio;

      // We only want this to apply to line annotations
      objectsState.moveableLineRef && objectsState.selectedObjects[0]?.type?.toLowerCase().includes("line")
        ? objectsState.moveableLineRef.request("resizable", { deltaWidth }, true)
        : null;

      // We don't want line annotations affected by this
      objectsState.moveableRef && !objectsState.selectedObjects[0]?.type?.toLowerCase().includes("line")
        ? objectsState.moveableRef.request("resizable", { keepRatio, deltaWidth, horizontal: true }, true)
        : null;
      setWidth(null);
    });
  };
  const handleHeightChange = (value) => {
    setHeight(value);
    debounce(() => {
      const newHeight = parseInt(value);
      const deltaHeight = newHeight - selectedObjectState.height;
      const keepRatio = objectsState.selectedObjects[0].lockAspectRatio;
      objectsState.moveableRef ? objectsState.moveableRef.request("resizable", { keepRatio, deltaHeight }, true) : null;
      setHeight(null);
    });
  };

  const onWidthInputChange = (e) => {
    handleWidthChange(e.target.value);
  };

  const onHeightInputChange = (e) => {
    handleHeightChange(e.target.value);
  };

  const hideFillColor =
    selectedObject.type === "lineArrow" ||
    selectedObject.type === "fillArrow" ||
    selectedObject.type === "solidLine" ||
    selectedObject.type === "dashedLine";

  const hideOutlineColor = selectedObject.type === OBJECT_TYPE_SHAPE;

  const hideFontColor =
    selectedObject.type === "circledLetter" ||
    selectedObject.type === "circledNumber" ||
    selectedObject.type === "squareNumber" ||
    selectedObject.type === "squareLetter";

  return (
    <div className="property-box-panel" style={{ overflowY: "scroll" }}>
      {/* <ObjectPropertyBoxTables /> */}
      {["pageImage", "video", "SCORM", "panoramic", "smartObject"].includes(selectedObject?.type ?? "") ? (
        <Section title="File Name" wrap={false}>
          <input
            style={{ width: "100%" }}
            onChange={(e) => {}}
            type="text"
            value={selectedObject.type === "panoramic" ? selectedObject.name : path.split("/").pop()}
          />
        </Section>
      ) : null}
      <Section title="Object Name" wrap={false}>
        <input
          style={{ width: "100%" }}
          onChange={(e) => {
            objectsDispatch({
              type: ObjectActionsType.SET_DISPLAY_NAME,
              payload: {
                objectId: selectedObject.objectId,
                displayName: e.target.value,
              },
            });
          }}
          type="text"
          value={displayName}
        />
      </Section>
      <Section wrap={false}>
        <FramedInput
          label="Arrangement"
          svgShowing={false}
          value={zIndex}
          disabled={selectedObject?.type === "hotspot"}
          inputType="number"
          isHovered={false}
          onInputChange={(e) => {
            const newZIndex = parseInt(e.target.value);
            objectsDispatch({
              type: ObjectActionsType.SET_Z_INDEX,
              payload: { objectId: selectedObject.objectId, zIndex: newZIndex },
            });
          }}
        />
      </Section>
      <Section title="Position" wrap>
        <div className="sub-section-nowrap">
          <FramedInput
            label="X"
            value={x}
            svgShowing={selectedObject.type === "table" ? false : true}
            inputType="number"
            isHovered={xHovered}
            disabled={masterFrameDisable}
            onFrameAdd={() => {
              objectsDispatch({
                type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                payload: {
                  objectId: selectedObject.objectId,
                  frame: {
                    timestamp: currentTime,
                    x: selectedObjectState.x,
                  },
                },
              });
            }}
            onFrameRemove={() => {
              objectsDispatch({
                type: ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME,
                payload: {
                  objectId: selectedObject.objectId,
                  timestamp: currentTime,
                  property: "x",
                },
              });
            }}
            onInputChange={(e) => {
              const newX = e.target.value ? parseInt(e.target.value) : 0;
              const deltaX = newX - (selectedObjectState.x ?? 0);
              objectsState.moveableRef && !objectsState.selectedObjects[0]?.type?.toLowerCase().includes("line")
                ? objectsState.moveableRef.request("draggable", { deltaX }, true)
                : null;
              objectsState.moveableLineRef && objectsState.selectedObjects[0]?.type?.toLowerCase().includes("line")
                ? objectsState.moveableLineRef.request("draggable", { deltaX }, true)
                : null;
              // TODO bounds check
            }}
          />
          <FramedInput
            label="Y"
            value={y}
            disabled={masterFrameDisable}
            inputType="number"
            svgShowing={selectedObject.type === "table" ? false : true}
            isHovered={yHovered}
            onFrameAdd={() => {
              objectsDispatch({
                type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                payload: {
                  objectId: selectedObject.objectId,
                  frame: {
                    timestamp: currentTime,
                    y: selectedObjectState.y,
                  },
                },
              });
            }}
            onFrameRemove={() => {
              objectsDispatch({
                type: ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME,
                payload: {
                  objectId: selectedObject.objectId,
                  timestamp: currentTime,
                  property: "y",
                },
              });
            }}
            onInputChange={(e) => {
              const newY = e.target.value ? parseInt(e.target.value) : 0;
              const deltaY = newY - (selectedObjectState.y ?? 0);
              objectsState.moveableRef && !objectsState.selectedObjects[0]?.type?.toLowerCase().includes("line")
                ? objectsState.moveableRef.request("draggable", { deltaY }, true)
                : null;
              objectsState.moveableLineRef && objectsState.selectedObjects[0]?.type?.toLowerCase().includes("line")
                ? objectsState.moveableLineRef.request("draggable", { deltaY }, true)
                : null;
            }}
          />
        </div>
        <div className="framed-input">
          <label className="framed-input-label">
            <div>Off Screen</div>

            <select
              value={"Choose"}
              disabled={masterFrameDisable}
              onChange={(e) => {
                switch (e.target.value) {
                  case DIRECTIONS.TOP: {
                    const newY = 0 - selectedObjectState.height;
                    objectsState.moveableRef ? objectsState.moveableRef.request("draggable", { y: newY }, true) : null;
                    objectsState.moveableLineRef
                      ? objectsState.moveableLineRef.request("draggable", { y: newY }, true)
                      : null;
                    break;
                  }
                  case DIRECTIONS.BOTTOM:
                    {
                      if (viewportDOMElementHeight) {
                        objectsState.moveableRef
                          ? objectsState.moveableRef.request("draggable", { y: viewportDOMElementHeight }, true)
                          : null;
                        objectsState.moveableLineRef
                          ? objectsState.moveableLineRef.request("draggable", { y: viewportDOMElementHeight }, true)
                          : null;
                      }
                    }
                    break;
                  case DIRECTIONS.LEFT: {
                    const padding = 330;
                    const newX = 0 - selectedObjectState.width - padding;
                    objectsState.moveableRef ? objectsState.moveableRef.request("draggable", { x: newX }, true) : null;
                    objectsState.moveableLineRef
                      ? objectsState.moveableLineRef.request("draggable", { x: newX }, true)
                      : null;
                    break;
                  }
                  case DIRECTIONS.RIGHT: {
                    if (viewportDOMElementWidth) {
                      const padding = 250;
                      const newX = 1515;
                      objectsState.moveableRef
                        ? objectsState.moveableRef.request("draggable", { x: newX }, true)
                        : null;
                      objectsState.moveableLineRef
                        ? objectsState.moveableLineRef.request("draggable", { x: newX }, true)
                        : null;
                    }
                    break;
                  }
                }
              }}
            >
              <option disabled key={-1} value={"Choose"}>
                -- choose a direction --
              </option>
              <option value={DIRECTIONS.TOP}>{DIRECTIONS.TOP}</option>
              <option value={DIRECTIONS.BOTTOM}>{DIRECTIONS.BOTTOM}</option>
              <option value={DIRECTIONS.LEFT}>{DIRECTIONS.LEFT}</option>
              <option value={DIRECTIONS.RIGHT}>{DIRECTIONS.RIGHT}</option>
            </select>
          </label>
        </div>
      </Section>
      <Section title="Dimensions" wrap={false}>
        <FramedInput
          label="W"
          value={localW ?? width}
          inputType="number"
          disabled={masterFrameDisable}
          svgShowing={selectedObject.type === "table" ? false : true}
          isHovered={widthHovered}
          onFrameAdd={() => {
            objectsDispatch({
              type: ObjectActionsType.UPSERT_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                frame: {
                  timestamp: currentTime,
                  width: valueToPercentage(selectedObjectState.width, viewportDOMElementWidth),
                },
              },
            });
          }}
          onFrameRemove={() => {
            objectsDispatch({
              type: ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                timestamp: currentTime,
                property: "width",
              },
            });
          }}
          onInputChange={onWidthInputChange}
        />
        <FramedInput
          label="H"
          value={localH ?? height}
          inputType="number"
          disabled={masterFrameDisable}
          isHovered={heightHovered}
          svgShowing={selectedObject.type === "table" ? false : true}
          canEditHeight={canEditHeight}
          onFrameAdd={() => {
            objectsDispatch({
              type: ObjectActionsType.UPSERT_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                frame: {
                  timestamp: currentTime,
                  height: valueToPercentage(selectedObjectState.height, viewportDOMElementHeight),
                },
              },
            });
          }}
          onFrameRemove={() => {
            objectsDispatch({
              type: ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                timestamp: currentTime,
                property: "height",
              },
            });
          }}
          onInputChange={onHeightInputChange}
        />
      </Section>
      <Section wrap>
        <FramedInput
          label="Rotation (Degrees)"
          value={rotation}
          inputType="number"
          svgShowing={selectedObject.type === "table" ? false : true}
          isHovered={rotationHovered}
          canEditRotation={canEditRotation}
          disabled={masterFrameDisable}
          onFrameAdd={() => {
            objectsDispatch({
              type: ObjectActionsType.UPSERT_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                frame: {
                  timestamp: currentTime,
                  rotation: selectedObjectState.rotation,
                },
              },
            });
          }}
          onFrameRemove={() => {
            objectsDispatch({
              type: ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                timestamp: currentTime,
                property: "rotation",
              },
            });
          }}
          onInputChange={(e) => {
            const newRotation = parseInt(e.target.value);
            const deltaRotation = newRotation - selectedObjectState.rotation;
            objectsState.moveableRef && !objectsState.selectedObjects[0]?.type?.toLowerCase().includes("line")
              ? objectsState.moveableRef.request("rotatable", { deltaRotate: deltaRotation, rotate: newRotation }, true)
              : null;
            objectsState.moveableLineRef && objectsState.selectedObjects[0]?.type?.toLowerCase().includes("line")
              ? objectsState.moveableLineRef.request(
                  "rotatable",
                  { deltaRotate: deltaRotation, rotate: newRotation },
                  true,
                )
              : null;
          }}
        />
        <FramedInput
          label="Opacity"
          value={opacity.toString()}
          inputType="number"
          isHovered={opacityHovered}
          step={0.1}
          disabled={masterFrameDisable}
          svgShowing={selectedObject.type === "table" ? false : true}
          canEditOpacity={canEditOpacity}
          min={0}
          max={1}
          onFrameAdd={() => {
            objectsDispatch({
              type: ObjectActionsType.UPSERT_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                frame: {
                  timestamp: currentTime,
                  opacity: selectedObjectState.opacity,
                },
              },
            });
          }}
          onFrameRemove={() => {
            objectsDispatch({
              type: ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                timestamp: currentTime,
                property: "opacity",
              },
            });
          }}
          onInputChange={(e) => {
            const newOpacity = parseFloat(e.target.value);
            selectedObjectDispatch({
              type: SelectedObjectActionTypes.SET_OPACITY,
              payload: newOpacity,
            });
            objectsDispatch({
              type: ObjectActionsType.SET_OPACITY,
              payload: { objectId: selectedObject.objectId, opacity: newOpacity },
            });
            objectsDispatch({
              type: ObjectActionsType.UPSERT_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                frame: {
                  timestamp: currentTime,
                  opacity: newOpacity,
                },
              },
            });
          }}
        />
        <FramedInput
          label="Maintain Ratio"
          value={lockAspectRatio}
          inputType="checkbox"
          canEditRatio={canEditLockRatio}
          // Is there any component that can "ANIMATE" the ratio property?
          svgShowing={false}
          // svgShowing={selectedObject.type === "table" ? false : true}
          onInputChange={(e) => {
            const newLockAspectRatio = e.target.checked;
            objectsDispatch({
              type: ObjectActionsType.SET_LOCK_ASPECT_RATIO,
              payload: { objectId: selectedObject.objectId, lockAspectRatio: newLockAspectRatio },
            });
          }}
        />
        <FramedInput
          label="Visible"
          value={visible}
          disabled={selectedObject?.type === "SCORM" || selectedObject?.type === "video"}
          inputType="checkbox"
          // Is there any component that can "ANIMATE" the visible property?
          svgShowing={false}
          // svgShowing={selectedObject.type === "table" ? false : true}
          onInputChange={(e) => {
            const newVisible = e.target.checked;
            objectsDispatch({
              type: ObjectActionsType.UPDATE_OBJECT,
              payload: {
                objectId: selectedObject.objectId,
                object: {
                  isDisplayed: newVisible,
                },
              },
            });
          }}
        />
      </Section>

      {selectedObject?.type === "pageImage" ? (
        <>
          <DropSeparator title="Cropping" setShowMenu={setShowCroppingMenu} showMenu={showCroppingMenu} />
          <div className="object-properties-section object-properties-section-border" hidden={!showCroppingMenu}>
            <EditImageMenu />
          </div>
        </>
      ) : selectedObject?.type === "smartObject" ? (
        <>
          {/* <DropSeparator title="Cropping" setShowMenu={setShowCroppingMenu} showMenu={showCroppingMenu} /> */}
          {/* Here goes the working zooming and cropping menu */}
        </>
      ) : null}

      {selectedObject?.type !== "video" &&
      selectedObject?.type !== "hotspot" &&
      selectedObject?.type !== "pageImage" &&
      selectedObject?.type !== "smartObject" &&
      selectedObject?.type !== "panoramic" &&
      selectedObject?.type !== "SCORM" ? (
        <>
          <DropSeparator title="Colors" setShowMenu={setShowColorsMenu} showMenu={showColorsMenu} />
          <div hidden={!showColorsMenu}>
            <ColorInput
              isModalShown={isModalShown}
              selectedObject={selectedObject}
              hideFillColor={!hideFillColor}
              hideOutlineColor={!hideOutlineColor}
              hideBorderWidth={selectedObject.type === "table"}
              hideFontColor={hideFontColor}
            />
          </div>
        </>
      ) : null}

      {selectedObject?.type !== "video" &&
      selectedObject?.type !== "diagram" &&
      selectedObject?.type !== "SCORM" &&
      pageContex.pageManifest.basePageType === "freeForm" ? (
        <>
          <Separator title="Timeline" />
          {/* {isInTimeline(selectedObject) && ( */}
          <Section wrap>
            {isInTimeline(animatedObject) && (
              <div className="sub-section-nowrap">
                <FramedInput
                  label="Start"
                  // value={animatedObject?.start ?? 0}
                  value={
                    conversion.millisecondsToQuarterSeconds(conversion.secondToMillisecond(animatedObject?.start)) ?? 0
                  }
                  inputType="number"
                  isHovered={false}
                  step={0.25}
                  svgShowing={selectedObject.type === "table" ? false : true}
                  onInputChange={(e) => {
                    if (!animatedObject) return;
                    const newStart = parseFloat(e.target.value);
                    const newEnd = animatedObject.end;
                    const sequenceLength = tl.sequenceLength;
                    const id = animatedObject.id;
                    const firstFrame = animatedObject.frames?.[0];
                    const firstFrameStart = firstFrame?.timestamp;
                    if (firstFrameStart && newStart > firstFrameStart) {
                      return;
                    }
                    objectsDispatch({
                      type: ObjectActionsType.UPDATE_OBJECT_START_END,
                      payload: {
                        objectId: id,
                        start: newStart < 0 ? 0 : snapToGrid(newStart, tl.subUnitsPerUnit),
                        // send null if the end is the same as the sequence length
                        end:
                          typeof newEnd === "number" ? snapToGrid(newEnd ?? sequenceLength, tl.subUnitsPerUnit) : null,
                      },
                    });
                  }}
                />
                <FramedInput
                  label="End"
                  value={animatedObject?.end ?? tl.sequenceLength}
                  inputType="number"
                  isHovered={false}
                  step={0.25}
                  svgShowing={selectedObject.type === "table" ? false : true}
                  onInputChange={(e) => {
                    if (!animatedObject) return;
                    const newEnd = parseFloat(e.target.value);
                    const newStart = animatedObject.start;
                    const sequenceLength = tl.sequenceLength;
                    const id = animatedObject.id;
                    const lastFrame = animatedObject.frames?.[animatedObject.frames?.length - 1];
                    const lastFrameEnd = lastFrame?.timestamp;
                    if (lastFrameEnd && newEnd < lastFrameEnd) {
                      return;
                    }
                    objectsDispatch({
                      type: ObjectActionsType.UPDATE_OBJECT_START_END,
                      payload: {
                        objectId: id,
                        start: snapToGrid(newStart, tl.subUnitsPerUnit),
                        // send null if the end is the same as the sequence length
                        end: newEnd >= sequenceLength ? null : snapToGrid(newEnd, tl.subUnitsPerUnit),
                      },
                    });
                  }}
                />
              </div>
            )}
            {!isInTimeline(animatedObject) && (
              <div
                className="editImageButton"
                onClick={() => {
                  objectsDispatch({
                    type: ObjectActionsType.ADD_ANIMATED_OBJECT,
                    objectId: selectedObject.objectId,
                  });
                }}
              >
                Add To Timeline
              </div>
            )}
            {isInTimeline(animatedObject) && (
              <div
                className="editImageButton"
                onClick={() => {
                  objectsDispatch({
                    type: ObjectActionsType.DELETE_ANIMATED_OBJECT,
                    objectId: selectedObject.objectId,
                  });
                }}
              >
                Remove From Timeline
              </div>
            )}
          </Section>
        </>
      ) : null}
      {selectedObject?.type === "hotspot" && <HotspotClicks />}
    </div>
  );
}
function HotspotClicks() {
  const { selectedObjects } = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const hotspot = selectedObjects[0] as HotspotObject;
  const clicks = [hotspot, ...hotspot.nextClicks];
  return (
    <>
      <PBMenuHeader title="Hotspot Actions">
        <HotspotOptions hotspot={hotspot} />
        <div className="hotspot-task-list">
          {clicks.map((hpClick, i) => {
            //order matters
            return (
              <ClickGroup
                hotspot={hpClick}
                clickIndex={i}
                key={i}
                objectId={hotspot.objectId}
                onClickDelete={() => {
                  // delete click
                  objectsDispatch({
                    type: ObjectActionsType.UPDATE_HOTSPOT,
                    payload: {
                      objectId: hotspot.objectId,
                      clickIndex: i,
                      hotspot: {},
                      newClicks: hotspot.nextClicks.filter((_, index) => index !== i - 1),
                    },
                  });
                }}
              />
            );
          })}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              borderTop: "1px solid #ECEDEE",
              padding: "5px",
            }}
          >
            <div
              className="add-new-click-button"
              onClick={() => {
                // add new click
                objectsDispatch({
                  type: ObjectActionsType.UPDATE_HOTSPOT,
                  payload: {
                    objectId: hotspot.objectId,
                    action: { type: "ADD_CLICK" },
                  },
                });
              }}
            >
              {/* <PlusButton className="add-click-interactivity-button" /> */}
              <p>Add Click</p>
            </div>
          </div>
        </div>
      </PBMenuHeader>
    </>
  );
}
interface FramedInputProps {
  label: string | null;
  hasLabel?: boolean;
  value: string | number | boolean;
  isHovered: boolean;
  inputType: "number" | "text" | "checkbox" | "select" | "range" | "rangeWide";
  onFrameAdd?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onFrameRemove?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onInputChange: React.ChangeEventHandler<HTMLInputElement>;
  onInputBlur?: React.ChangeEventHandler<HTMLInputElement>;
  step?: number;
  options?: string[];
  canEditHeight?: boolean;
  canEditRatio?: boolean;
  canEditOpacity?: boolean;
  canEditRotation?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  svgShowing?: boolean;
  rangeMin?: number;
  rangeMax?: number;
}

export function FramedInput({
  isHovered,
  label,
  onInputChange,
  onInputBlur,
  value,
  inputType,
  onFrameAdd,
  onFrameRemove,
  step,
  options,
  canEditHeight,
  canEditRatio,
  canEditOpacity,
  canEditRotation,
  disabled,
  min = -Infinity,
  max = Infinity,
  svgShowing,
  rangeMin = -Infinity,
  rangeMax = Infinity,
}: FramedInputProps) {
  const objectsState = useObjectsState();
  const [tl] = useTimeline();
  const selectedObject: BaseObject | undefined = objectsState.selectedObjects[0];
  const animatedObject = objectsState.animatedObjects.find((o) => o.id === selectedObject?.objectId);
  const shouldGray = () => {
    if (disabled) return true;
    if (canEditHeight !== false && canEditRatio !== false && canEditOpacity !== false && canEditRotation !== false)
      return false;
    return true;
  };
  const gray = shouldGray();
  const pointerEvents =
    canEditHeight !== false && canEditRatio !== false ? { pointerEvents: "all" } : { pointerEvents: "none" };

  const onRangeInputBlur = (event: ChangeEvent<HTMLInputElement>) => {
    onInputBlur && onInputBlur(event);
  };

  const containerStyle = {
    opacity: gray ? 0.5 : 1,
  };

  const shouldKeyframeShow = () => {
    if (disabled) return false;
    if (gray) return false;
    // if (inputType === "checkbox") return false;
    if (label === "Start" || label === "End") return false;
    return true;
  };

  return (
    <div className="framed-input" style={containerStyle}>
      <label className="framed-input-label">
        {label && (
          <div className={label === "Zoom" && selectedObject.type === "panoramic" ? "section-title" : ""}>{label}</div>
        )}
        {inputType === "checkbox" && (
          <div>
            <input
              id={label ?? undefined}
              onChange={canEditRatio !== false && disabled !== true ? onInputChange : () => {}}
              type={inputType}
              checked={!!value}
            />
          </div>
        )}
        {inputType === "select" && (
          <select id={label} value={value}>
            {options?.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
        {inputType === "range" && (
          <div className="range-input-wrapper">
            <input type="range" min={rangeMin} max={rangeMax} onChange={onInputChange} value={value?.toString()} />
            <input
              id={label ?? undefined}
              onBlur={!disabled ? onRangeInputBlur : undefined}
              onInput={() => {
                if (disabled) return;
              }}
              onChange={!disabled ? onInputChange : undefined}
              type={"number"}
              value={value.toString()}
              step={step ?? 1}
              min={rangeMin}
              max={rangeMax}
              className="range-input-num"
            />
          </div>
        )}
        {inputType === "rangeWide" && typeof value === "number" && (
          <div className="range-input-wrapper" style={{ maxWidth: "100%" }}>
            <input
              type="range"
              min={rangeMin}
              max={rangeMax}
              onChange={onInputChange}
              value={value.toString()}
              style={{ maxWidth: "100%" }}
            />
            <input
              value={value}
              id={label ?? undefined}
              onBlur={!disabled ? onRangeInputBlur : undefined}
              onInput={() => {
                if (disabled) return;
              }}
              onChange={!disabled ? onInputChange : undefined}
              type={"number"}
              step={step ?? 1}
              min={rangeMin}
              max={rangeMax}
              className="range-input-num"
              style={{ maxWidth: "100%" }}
            />
          </div>
        )}
        {(inputType === "text" || inputType === "number") && (
          <>
            <div
              style={{
                maxWidth: label === "Start" || label === "End" ? "100px" : "75px",
                position: "relative",
                marginRight: label === "Start" || label === "End" ? "5px" : "0px",
              }}
            >
              <input
                id={label}
                onInput={(event) => {
                  if (disabled) return;
                  const value = event.target.value;
                  if (value < min) {
                    event.target.value = min;
                  } else if (value > max) {
                    event.target.value = max;
                  }
                }}
                onChange={
                  !disabled && canEditHeight !== false && canEditOpacity !== false && canEditRotation !== false
                    ? onInputChange
                    : undefined
                }
                type={inputType}
                value={value}
                step={step ?? 1}
                min={min}
                max={max}
              />
              {(label === "Start" || label === "End") && (
                <div
                  className="inputUnit"
                  style={{ right: "18px", top: "4px", position: "absolute", fontSize: "smaller" }}
                >
                  sec
                </div>
              )}
            </div>
          </>
        )}
      </label>
      {shouldKeyframeShow() && (
        <div style={{ minWidth: "25px", maxHeight: "25px" }}>
          {svgShowing !== false && animatedObject !== undefined ? (
            <FrameSVG
              className={isHovered ? "selectedFrame" : "unselectedFrame"}
              onClick={(e) => {
                if (disabled) return;
                //if there is a frame at the current time, remove that frame's x value
                if (isHovered) {
                  onFrameRemove?.(e);
                }
                // if there is no frame at the current time, add a frame with the current x value
                else {
                  onFrameAdd?.(e);
                }
              }}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}

interface SeparatorProps {
  title: string;
  color?: "green" | "gray" | "lightGray";
}

interface DropSeparatorProps extends SeparatorProps {
  setShowMenu: Dispatch<SetStateAction<boolean>>;
  showMenu: boolean;
}

export function Separator({ title }: any) {
  return (
    <div className="separator">
      <div className="separator-title">{title}</div>
    </div>
  );
}

export function DropSeparator({ title, setShowMenu, showMenu, color = "gray" }: DropSeparatorProps) {
  let bgColorValue = "";
  let colorValue = "";
  switch (color) {
    case "green":
      colorValue = "var(--cpat-midgreen)";
      bgColorValue = "var(--template-color)";
      break;
    case "gray":
      colorValue = "var(--cpat-gray)";
      bgColorValue = "white";
      break;
    case "lightGray":
      colorValue = "var(--panel-gray)";
      bgColorValue = "var(--template-color)";
      break;
    default:
      colorValue = "#333333";
  }
  return (
    <div
      className="separator"
      style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: colorValue }}
      onClick={() => setShowMenu(!showMenu)}
    >
      <div className="separator-title" style={{ color: bgColorValue }}>
        {title}
      </div>
      <ArrowIcon style={showMenu ? { transform: "rotate(180deg)" } : {}} />
    </div>
  );
}

export function DropSeparatorSmoll({ title, setShowMenu, showMenu }: DropSeparatorProps) {
  return (
    <div
      className="separatorSmoll"
      style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      onClick={() => setShowMenu(!showMenu)}
    >
      <div className="separator-title-smoll">{title}</div>
      <ArrowIcon className="blackFill" style={showMenu ? { transform: "rotate(180deg)" } : {}} />
    </div>
  );
}

type SectionType = {
  title?: string | null;
  children: ReactNode;
  wrap: boolean;
  flexDirection?: "row" | "column";
  separator?: boolean;
  boldTitle?: boolean;
  padding?: boolean;
};

export function Section({
  title,
  children,
  wrap,
  flexDirection = "row",
  separator = true,
  boldTitle = true,
  padding = true,
}: SectionType) {
  return (
    <div
      className={`object-properties-section${separator ? " object-properties-section-border" : ""}`}
      style={{ padding: padding ? "none" : "none", paddingTop: padding ? "none" : "none" }}
    >
      {!!title && <div className={boldTitle ? "section-title" : ""}>{title}</div>}
      <div className="section-content" style={{ flexWrap: wrap ? "wrap" : "nowrap", flexDirection: flexDirection }}>
        {children}
      </div>
    </div>
  );
}
