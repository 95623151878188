import React, { useLayoutEffect, useMemo } from "react";
import Moveable from "react-moveable";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";
import { useTimeline } from "../../contexts/TimelineProvider/TimelineProvider";
import {
  SelectedObjectActionTypes,
  useSelectedObjectDispatch,
  useSelectedObjectState,
} from "../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { getClipObject } from "./lib/ables/cropFunctions";
import { createLogger, getSVGPadding } from "../../utils";
import { useMovableElementsPlaneState } from "../../contexts/MovableElementsPlaneProvider";
import CropRotatable from "./lib/ables/CropRotatable";
import CropResizable from "./lib/ables/CropResizable";
import { useMiscUI } from "../../contexts/MiscUI/MiscUIProvider";
import { OBJECT_TYPE_PANORAMIC } from "../../const";
import { useLessonPagesState } from "../../contexts/LessonPagesProvider/LessonPagesProvider";
import { percentageToValue, valueToPercentage } from "../../utils/Conversion";
import { useObjectPosition } from "../../hooks/useObjectPosition";

const log = createLogger("AbstractMoveable", { color: "blue" });
const targetIdMap = new WeakMap();

export function AbstractMoveable({ pageDims }: any) {
  const objectsState = useObjectsState();
  const selectedObjectState = useSelectedObjectState();
  const { viewportDOMElementHeight, viewportDOMElementWidth } = useMovableElementsPlaneState();
  const objectsDispatch = useObjectsDispatch();
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const isCropping = objectsState.isCropping;
  const selectedObject = objectsState.selectedObjects[0];
  const [localKeepAspectRatio, setLocalKeepAspectRatio] = React.useState<boolean | null>(null);
  const {
    rotation: finalRotation,
    position: [framedX, framedY],
    size: [framedWidth, framedHeight],
  } = useObjectPosition(
    selectedObject?.objectId,
    selectedObject?.top,
    selectedObject?.left,
    selectedObject?.width,
    selectedObject?.height,
    selectedObject?.rotation,
    selectedObject?.opacity,
  );
  const [tl] = useTimeline();
  const [miscUI] = useMiscUI();
  const snapToGrid = miscUI.snapToGrid;
  const moveableRef = React.useRef<any>(null);
  const { selectedPanel } = useLessonPagesState();
  const isFreeFormPoly = objectsState.selectedObjects[0]?.type === "freeFormPoly";
  // mapping objects, expensive. consider memoizing
  const guides = objectsState.objectList.map((object) => {
    return `[data-objectid="${object.objectId}"]`;
  });
  const hasClipCoordinates = (object: any) => {
    if (object && ("clipPath" in object || "clipPathString" in object)) {
      if (object.clipPathString) {
        return true;
      } else if (object.clipPath) {
        return !Object.values(object.clipPath).every((value) => value === 0);
      }
    }
    return false;
  };

  const isCroppedImage = hasClipCoordinates(objectsState.selectedObjects[0]);

  const selected = objectsState.selectedObjects
    // .filter((x) => !(x.type?.includes("Arrow") || x.type?.includes("Line")))
    .map((object) => {
      if (isCroppedImage && isCropping) {
        return `[data-ref="${object.objectId}"]`;
      }
      return `[data-objectid="${object.objectId}"]`;
    });

  const hasRotation = useMemo(() => {
    if (objectsState.selectedObjects.length > 0) {
      if (
        objectsState.selectedObjects[0]?.type &&
        // TODO: rollback adding the "video", "table", "SCORM" types
        [OBJECT_TYPE_PANORAMIC].includes(objectsState.selectedObjects[0]?.type)
      ) {
        return false;
      }
    }
    return true;
  }, [objectsState]);

  if (objectsState.selectedObjects.length > 1) {
    selected.filter((x) => !(x.type?.includes("Arrow") || x.type?.includes("Line")));
  }

  const determineAnimatedObjectOutOfBoundaries = (objectId: string) => {
    const animatedObject = objectsState.animatedObjects.find((object) => object.id === objectId);
    if (animatedObject) {
      return (
        tl.scrubbingCurrentTime < animatedObject.start ||
        tl.scrubbingCurrentTime > (animatedObject.end ?? tl.sequenceLength)
      );
    }
  };

  useLayoutEffect(() => {
    if (moveableRef.current) {
      moveableRef.current.updateRect();
    }
  });

  const className = (isDisplayed: boolean, grayBorderIsOn: boolean) => {
    const selectedObjectType = objectsState.selectedObjects[0] ? objectsState.selectedObjects[0]?.type : null;
    return [
      `${typeof isDisplayed === "undefined" || isDisplayed ? "" : "target-not-displayed"} ${grayBorderIsOn}`,
      selectedObjectType === OBJECT_TYPE_PANORAMIC && miscUI.objectLocked ? "panoramic-moveable" : "",
      selectedObjectType === "smartObject" && selectedPanel === "advanced" ? "smartobject-moveable" : "",
      selectedObjectType === "freeFormPoly" && selectedPanel === "advanced" ? "advanced-moveable" : "",
    ].join(" ");
  };
  const getRenderDirections = () => {
    if (
      objectsState.selectedObjects[0]?.type?.includes("Arrow") ||
      objectsState.selectedObjects[0]?.type?.includes("Line")
    ) {
      return ["e", "w"];
    }

    if (isCroppedImage) {
      return ["nw", "n", "ne", "e", "sw", "s", "se", "w"];
    }
    return ["nw", "ne", "sw", "se"];
  };

  const canResize = () => {
    if (isCroppedImage && isCropping) {
      return true;
    }
    if (isCroppedImage) {
      return true;
    }
    return true;
  };

  const getLockAspectRatio = () => {
    if (isCropping && localKeepAspectRatio === false) {
      return false;
    }
    return objectsState.selectedObjects[0]?.lockAspectRatio ?? false;
  };

  const renderDirections = getRenderDirections();
  return (
    <Moveable
      target={selected}
      ref={(ref) => {
        moveableRef.current = ref;
        if (ref) {
          objectsDispatch({
            type: ObjectActionsType.SET_MOVEABLE_OBJECT_REF,
            moveableRef: ref,
          });
        }
      }}
      renderDirections={renderDirections}
      checkInput
      resizable={canResize()}
      throttleResize={0}
      draggable={!miscUI.objectLocked && !isCropping}
      scalable={!miscUI.objectLocked}
      rotatable={hasRotation && !miscUI.objectLocked}
      snappable={snapToGrid}
      elementGuidelines={guides}
      triggerAblesSimultaneously={false}
      clipTargetBounds={true}
      clippable={isCropping}
      cropresizable={isCroppedImage}
      cropscalable={isCroppedImage}
      ables={isCroppedImage ? [CropResizable, CropRotatable] : []}
      props={{
        cropresizable: isCroppedImage,
      }}
      clipRelative={false}
      clipArea={false}
      dragWithClip={true}
      defaultClipPath={"inset"}
      keepRatio={getLockAspectRatio()}
      originRelative={true}
      // originDraggable={true}
      stopPropagation={true}
      className={className(true, false)}
      onRenderStart={({ inputEvent }) => {
        if (inputEvent) {
          inputEvent.stopPropagation();
        }
      }}
      onRotateStart={({ target, clientX, clientY, datas }) => {}}
      onRotate={({ target, rotate, transform, datas, delta, ...rest }) => {
        datas.rotation = rotate;
        target.style.transform = transform;
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_ROTATION,
          payload: rotate,
        });
      }}
      onRotateEnd={({ datas, target }) => {
        const outOfTimeBoundaries = determineAnimatedObjectOutOfBoundaries(objectsState.selectedObjects[0].objectId);
        if (outOfTimeBoundaries) {
          return (target.style.transform = datas.initialTransform);
        }
        if (typeof datas.rotation === "number") {
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              rotation: datas.rotation,
            },
          });
        }
      }}
      onResizeStart={({ target, datas }) => {
        datas.initialTransform = target.style.transform;
        datas.width = target.clientWidth;
        datas.height = target.clientHeight;
        datas.initialWidth = target.clientWidth;
        datas.initialHeight = target.clientHeight;

        if (isFreeFormPoly) {
          const ffAnnotation = objectsState.selectedObjects[0];
          const points = ffAnnotation.points;
          const padding = getSVGPadding(ffAnnotation.strokeWidth, datas.width, datas.height);
          const percentPoints = points.map((point) => {
            return {
              ...point,
              xPercent: ((point.x - padding) / (datas.width - 2 * padding)) * 100,
              yPercent: ((point.y - padding) / (datas.height - 2 * padding)) * 100,
            };
          });
          datas.percentPoints = percentPoints;
        }
        datas.initialTransform = target.style.transform;
        datas.width = target.clientWidth;
        datas.height = target.clientHeight;
      }}
      onResize={({ target, width: newWidth, height: newHeight, drag, datas }) => {
        if (selectedPanel === "advanced" && !isFreeFormPoly) return null;
        if (isFreeFormPoly) {
          if (newWidth < 50 || newHeight < 50) {
            return;
          }
        }

        target.style.width = `${newWidth}px`;
        target.style.height = `${newHeight}px`;
        target.style.transform = drag.transform;
        const beforeTranslate = drag.beforeTranslate;
        const oldWidth = datas.width;
        const oldHeight = datas.height;
        datas.left = beforeTranslate[0];
        datas.top = beforeTranslate[1];
        datas.width = newWidth;
        datas.height = newHeight;
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_X,
          payload: beforeTranslate[0],
        });
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_Y,
          payload: beforeTranslate[1],
        });
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_WIDTH,
          payload: newWidth,
        });
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_HEIGHT,
          payload: newHeight,
        });

        /**
         * LEGACY STUFF
         */
        /**
         * Triangle
         */
        if (target.firstChild?.nodeName === "polygon" && target.getAttribute("name") === "triangle") {
          target.firstChild.attributes.points.nodeValue = `4,${target.clientHeight - 4} ${
            (target.clientWidth - 2) / 2
          },4 ${target.clientWidth - 4},${target.clientHeight - 4}`;
        }
        /**
         * Ellipse
         */
        if ((target as any).firstChild.nodeName === "ellipse") {
          (target as any).firstChild.attributes.cx.nodeValue = `${target.clientWidth / 2}`;
          (target as any).firstChild.attributes.cy.nodeValue = `${target.clientHeight / 2}`;
          (target as any).firstChild.attributes.rx.nodeValue = `${target.clientWidth / 2 - 3}`;
          (target as any).firstChild.attributes.ry.nodeValue = `${target.clientHeight / 2 - 3}`;
        }
      }}
      onResizeEnd={({ datas, target }) => {
        const pixelX = datas.left;
        const pixelY = datas.top;
        const pixelWidth = datas.width;
        const pixelHeight = datas.height;
        const outOfTimeBoundaries = determineAnimatedObjectOutOfBoundaries(objectsState.selectedObjects[0].objectId);
        if (outOfTimeBoundaries) {
          return (target.style.transform = datas.initialTransform);
        }
        if (typeof pixelX === "number") {
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              x: pixelX,
            },
          });
        }
        log("pixelY", pixelY, "datas.deltaY", datas.deltaY);
        if (typeof pixelY === "number") {
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              y: pixelY,
            },
          });
        }
        if (typeof pixelWidth === "number") {
          const percentWidth = (pixelWidth / (viewportDOMElementWidth ?? 1)) * 100;
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              width: percentWidth,
            },
          });
        }
        if (typeof pixelHeight === "number") {
          const percentHeight = (pixelHeight / (viewportDOMElementHeight ?? 1)) * 100;
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              height: percentHeight,
            },
          });
        }

        /**
         * Resize the blurCutoutShapes as the object is resized
         */

        const blurCutoutShapes = selectedObjectState.blurCutoutShapes;
        if (blurCutoutShapes && blurCutoutShapes.length > 0) {
          const horizontalDelta = datas.width / datas.initialWidth;
          const verticalDelta = datas.height / datas.initialHeight;

          const newBlurCutoutShapes = blurCutoutShapes.map((blurCutoutShape) => ({
            ...blurCutoutShape,
            x: blurCutoutShape.x * horizontalDelta,
            y: blurCutoutShape.y * verticalDelta,
            w: blurCutoutShape.w * horizontalDelta,
            h: blurCutoutShape.h * verticalDelta,
          }));

          selectedObjectDispatch({
            type: SelectedObjectActionTypes.SET_BLUR_CUTOUT,
            payload: newBlurCutoutShapes,
          });

          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              blurCutoutShapes: newBlurCutoutShapes,
            },
          });
        }
      }}
      onDragStart={({ target, datas, clientX, clientY }) => {
        datas.initialTransform = target.style.transform;
        datas.deltaX = 0;
        datas.deltaY = 0;
      }}
      onDrag={({ target, datas, beforeTranslate, transform, delta, inputEvent }) => {
        datas.deltaX += delta[0];
        datas.deltaY += delta[1];
        target.style.transform = transform;
        datas.left = beforeTranslate[0];
        datas.top = beforeTranslate[1];

        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_X,
          payload: datas.left,
        });
        selectedObjectDispatch({
          type: SelectedObjectActionTypes.SET_Y,
          payload: datas.top,
        });
        objectsDispatch({
          type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
          payload: {
            time: tl.scrubbingCurrentTime,
            x: datas.left,
            y: datas.top,
          },
        });
      }}
      onDragEnd={({ target, datas }) => {
        const topFromObjState = percentageToValue(selectedObject.top, viewportDOMElementHeight || 0);
        const leftFromObjState = percentageToValue(selectedObject.left, viewportDOMElementWidth || 0);

        console.log("onDragEnd", datas.deltaX, leftFromObjState, datas.deltaY, topFromObjState);
        const pixelX = datas.left;
        const pixelY = datas.top;
        const outOfTimeBoundaries = determineAnimatedObjectOutOfBoundaries(objectsState.selectedObjects[0].objectId);
        if (outOfTimeBoundaries) {
          return (target.style.transform = datas.initialTransform);
        }
        const o = {};
        if (typeof pixelY === "number" && datas.deltaY !== 0) {
          o.y = pixelY;
        }
        if (typeof pixelX === "number" && datas.deltaY !== 0) {
          o.x = pixelX;
        }
        objectsDispatch({
          type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
          payload: {
            time: tl.scrubbingCurrentTime,
            ...o,
          },
        });
      }}
      onDragGroupStart={({ inputEvent, targets }) => {
        inputEvent.stopPropagation();
        targets.forEach((target) => {
          targetIdMap.set(target, {
            initialTransform: target.style.transform,
          });
        });
      }}
      onDragGroup={({ events }) => {
        events.forEach(({ target, beforeTranslate, transform }) => {
          targetIdMap.get(target).left = beforeTranslate[0];
          targetIdMap.get(target).top = beforeTranslate[1];
          target.style.transform = transform;
        });
      }}
      onDragGroupEnd={({ targets }) => {
        targets.forEach((target) => {
          const { left, top, initialTransform } = targetIdMap.get(target);
          const objectId = target.getAttribute("data-objectid") ?? undefined;
          if (!objectId) return;
          const isOutOfTimeBoundaries = determineAnimatedObjectOutOfBoundaries(objectId);
          if (isOutOfTimeBoundaries) {
            return (target.style.transform = initialTransform);
          }
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              x: left,
              y: top,
              objectId,
            },
          });
        });
      }}
      onClickGroup={(e) => {
        const selectedTarget = e.inputTarget;
        let elementWithObjectId: Element | null = selectedTarget;
        const maxIterations = 9;
        let selectedObjectId;
        selectedObjectId = elementWithObjectId.getAttribute("data-objectid");
        elementWithObjectId = elementWithObjectId.parentElement!;

        for (let i = 0; i < maxIterations; i++) {
          if (!selectedObjectId && elementWithObjectId) {
            selectedObjectId = elementWithObjectId.getAttribute("data-objectid");
            elementWithObjectId = elementWithObjectId.parentElement!;
          } else {
            break;
          }
        }
        if (!selectedObjectId) return;
        let type = ObjectActionsType.SET_SELECTED_OBJECT;
        if (e.inputEvent.ctrlKey) {
          type = ObjectActionsType.ADD_SELECTED_OBJECT;
        }
        objectsDispatch({
          type,
          payload: { objectId: selectedObjectId },
        });
      }}
      onClipStart={({ inputEvent }) => {
        inputEvent.stopPropagation();
        setLocalKeepAspectRatio(false);
      }}
      onClip={({ target, clipStyles, clipStyle, datas }) => {
        datas.clipStyle = clipStyle;
        target.style.clipPath = clipStyle;
        datas.top = parseFloat(clipStyles[0]);
        datas.right = parseFloat(clipStyles[1]);
        datas.bottom = parseFloat(clipStyles[2]);
        datas.left = parseFloat(clipStyles[3]);
      }}
      onClipEnd={({ datas, target }) => {
        setLocalKeepAspectRatio(null);
        const pixelWidth = framedWidth;
        const pixelHeight = framedHeight;
        const { top, right, bottom, left } = datas;
        // Calculate the width and height of the clipped area
        const clipWidth = pixelWidth - left - right;
        const clipHeight = pixelHeight - top - bottom;

        // Calculate the center of the clipped area
        const clipCenterX = left + clipWidth / 2;
        const clipCenterY = top + clipHeight / 2;

        const percentTop = (top / pixelHeight) * 100;
        const percentRight = (right / pixelWidth) * 100;
        const percentBottom = (bottom / pixelHeight) * 100;
        const percentLeft = (left / pixelWidth) * 100;
        const clipObject = {
          top: percentTop,
          right: percentRight,
          bottom: percentBottom,
          left: percentLeft,
        };

        const percentClipString = `inset(${percentTop}% ${percentRight}% ${percentBottom}% ${percentLeft}%)`;

        objectsDispatch({
          type: ObjectActionsType.SET_OBJECT_CLIP_PATH,
          payload: {
            clipPath: clipObject,
            clipPathString: percentClipString,
            objectId: objectsState.selectedObjects[0].objectId,
          },
        });

        const newTransformOrigin = {
          x: (clipCenterX / pixelWidth) * 100,
          y: (clipCenterY / pixelHeight) * 100,
        };
        const prevRect = target.getBoundingClientRect();
        target.style.transformOrigin = `${newTransformOrigin.x}% ${newTransformOrigin.y}%`;
        const newRect = target.getBoundingClientRect();
        const xDifference = prevRect.x - newRect.x;
        const yDifference = prevRect.y - newRect.y;

        objectsDispatch({
          type: ObjectActionsType.UPDATE_OBJECT,
          payload: {
            objectId: objectsState.selectedObjects[0].objectId,
            object: {
              transformOrigin: newTransformOrigin,
            },
          },
        });

        const pixelX = framedX + xDifference;

        const pixelY = framedY + yDifference;
        objectsDispatch({
          type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
          payload: {
            objectId: objectsState.selectedObjects[0].objectId,
            time: tl.scrubbingCurrentTime,
            x: pixelX,
            y: pixelY,
          },
        });

        // Apply the updated transform origin and shift to the outer div

        target.style.clipPath = percentClipString;
      }}
    />
  );
}
