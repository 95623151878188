import { AudioSource, EAudioManagerEvents, LoadedAudio } from "../../classes/GlobalAudioManager/types";
import { AudioManagerStoreActions, AudioManagerStoreState, EffectAudioState, NarrationAudioState } from "./types";
import GlobalAudioManager from "../../classes/GlobalAudioManager/GlobalAudioManager";
import { EInteractiveAudioType, LanguageType, NarrationAudio } from "../../models/IInteractiveAudio";
import {
  getAudiosAfterTime,
  getAudiosByLanguage,
  getBiggestDuration,
  getGroupEnd,
  getSecondary,
  getSortedAudios,
  getSortedNarrations,
  removeAudioFromList,
} from "./helpers";
import { ConflictType, useNarrationStore } from "../../panels/TimelinePanel/Right/CompositeNarrationRow/store";
import { create } from "zustand";
import { useAudioEffectStore } from "../../panels/TimelinePanel/Right/AudioEffectRow/store";
import { nanoid } from "../../lib/nanoId";

export const useAudioManagerStore = create<AudioManagerStoreState & AudioManagerStoreActions>()(
  (setState, getState) => {
    return {
      narrationAudios: [],
      audioEffects: [],
      audioTypes: new Map(),
      isLoading: false,
      isPlaying: false,
      isDirty: false,
      error: undefined,
      selectedAudio: null,
      selectedLanguage: LanguageType.PRIMARY,
      // Actions
      setLanguage: (language) => {
        setState({ selectedLanguage: language });
      },
      selectAudio: (objectId) => {
        setState({
          selectedAudio: objectId,
        });
      },
      setIsDirty: (isDirty) => {
        setState({
          isDirty,
        });
      },
      clearSelection: () => {
        setState({
          selectedAudio: null,
        });
      },
      getSelectedAudio: () => {
        const { selectedAudio, getNarrationAudio, getEffectAudio, audioTypes } = getState();

        if (!selectedAudio) {
          return null;
        }

        const type = audioTypes.get(selectedAudio);

        if (type === EInteractiveAudioType.NARRATION) {
          return getNarrationAudio(selectedAudio);
        }

        if (type === EInteractiveAudioType.EFFECT) {
          return getEffectAudio(selectedAudio);
        }

        return null;
      },
      getEffectAudio: (objectId) => {
        const { audioEffects } = getState();
        return audioEffects.find((audio) => audio.objectId === objectId) ?? null;
      },
      getNarrationAudio: (objectId) => {
        const { narrationAudios } = getState();
        return narrationAudios.find((audio) => audio.objectId === objectId) ?? null;
      },
      loadEffectAudio: async (objectId, src, audioProps) => {
        const { audioEffects, getEffectAudio, audioTypes, moveEffectAudio } = getState();
        const audio = getEffectAudio(objectId);

        if (audioProps.start === -1) {
          const lastAudio = audioEffects[audioEffects.length - 1];
          audioProps.start = lastAudio ? lastAudio.end : 0;
        }

        if (audio) {
          objectId = `${objectId}-${nanoid()}`;
        }

        if (src && src.length > 0) {
          const newEffect = {
            objectId,
            start: 0,
            end: 0,
            duration: 0,
            input: src,
            loading: true,
            ready: false,
            playing: false,
            type: EInteractiveAudioType.EFFECT,
            ...audioProps,
          };
          audioTypes.set(objectId, EInteractiveAudioType.EFFECT);
          const newAudioEffects = [...audioEffects, newEffect] as EffectAudioState[];

          setState({ audioEffects: newAudioEffects, audioTypes: new Map(audioTypes) });

          await GlobalAudioManager.loadAudio({
            objectId,
            input: src,
            start: audioProps.start ?? 0,
          });

          moveEffectAudio(objectId, audioProps.start ?? 0);
        }
      },
      loadNarrationAudio: async (objectId, src, audioProps) => {
        const { narrationAudios, moveNarrationAudio, getNarrationAudio, audioTypes } = getState();
        const audio = getNarrationAudio(objectId);

        // Setup start
        if (audioProps.start === -1) {
          const lastAudio = narrationAudios[narrationAudios.length - 1];
          const secondaryAudio = lastAudio ? getSecondary(lastAudio.objectId, narrationAudios) : undefined;

          audioProps.start =
            secondaryAudio && secondaryAudio.end > lastAudio?.end ? secondaryAudio.end : lastAudio?.end ?? 0;
        }

        if (audio) {
          console.debug(`Audio ${objectId} already exists`);
          return audio;
        } else if (src && src.length > 0) {
          narrationAudios.push({
            objectId,
            end: 0,
            duration: 0,
            input: src,
            loading: true,
            ready: false,
            playing: false,
            savedText: "",
            type: EInteractiveAudioType.NARRATION,
            language: LanguageType.PRIMARY,
            ...audioProps,
          });

          audioTypes.set(objectId, EInteractiveAudioType.NARRATION);

          setState({
            narrationAudios: getSortedNarrations(narrationAudios),
          });

          await GlobalAudioManager.loadAudio({
            objectId,
            input: src,
            start: audioProps.start,
          });

          // Reorganize
          if (audioProps?.language === LanguageType.SECONDARY && audioProps?.parentObjectId) {
            moveNarrationAudio(audioProps.parentObjectId, audioProps.start);
          } else {
            moveNarrationAudio(objectId, audioProps.start);
          }
        }
      },
      updateEffectAudio: (objectId, audioProps) => {
        const { audioEffects, getEffectAudio } = getState();
        const originalAudio = getEffectAudio(objectId);
        const audioIndex = audioEffects.findIndex((audio) => audio.objectId === objectId);

        if (originalAudio && audioProps) {
          const updatedAudio = { ...originalAudio, ...audioProps };
          audioEffects[audioIndex] = updatedAudio;
          setState({
            audioEffects: getSortedAudios([...audioEffects]) as EffectAudioState[],
          });

          return updatedAudio;
        } else if (originalAudio) {
          return originalAudio;
        } else {
          return null;
        }
      },
      updateNarrationAudio: (objectId, audioProps) => {
        const { narrationAudios, getNarrationAudio } = getState();
        const originalAudio = getNarrationAudio(objectId);
        const audioIndex = narrationAudios.findIndex((audio) => audio.objectId === objectId);

        if (originalAudio && audioProps) {
          const updatedAudio = { ...originalAudio, ...audioProps };
          narrationAudios[audioIndex] = updatedAudio;
          setState({
            narrationAudios: getSortedAudios([...narrationAudios]) as NarrationAudioState[],
          });

          return updatedAudio;
        } else if (originalAudio) {
          return originalAudio;
        } else {
          return null;
        }
      },
      updateAudio: (objectId, audioProps) => {
        const { audioTypes, updateEffectAudio, updateNarrationAudio } = getState();

        if (audioTypes.get(objectId) === EInteractiveAudioType.EFFECT) {
          // Effect
          return updateEffectAudio(objectId, audioProps as Partial<EffectAudioState>);
        } else {
          // Narration
          return updateNarrationAudio(objectId, audioProps as Partial<NarrationAudioState>);
        }
      },
      removeEffectAudio: (objectId) => {
        const { audioEffects, getEffectAudio } = getState();
        const audio = getEffectAudio(objectId);
        const audioIndex = audioEffects.findIndex((audio) => audio.objectId === objectId);

        if (audio) {
          delete audioEffects[audioIndex];

          setState({
            audioEffects: audioEffects.filter((audio) => !!audio),
          });
        }
      },
      removeNarrationAudio: (objectId) => {
        const { narrationAudios, getNarrationAudio } = getState();
        const audio = getNarrationAudio(objectId);
        const audioIndex = narrationAudios.findIndex((audio) => audio.objectId === objectId);

        if (audio) {
          delete narrationAudios[audioIndex];

          setState({
            narrationAudios: narrationAudios.filter((audio) => !!audio),
          });
        }
      },
      removeAudio: (objectId) => {
        const { audioTypes, removeEffectAudio, removeNarrationAudio } = getState();

        if (audioTypes.get(objectId) === EInteractiveAudioType.EFFECT) {
          return removeEffectAudio(objectId);
        } else {
          return removeNarrationAudio(objectId);
        }
      },
      resetEffects: () => {
        setState({
          audioEffects: [],
          isLoading: false,
          isDirty: false,
          error: undefined,
          isPlaying: false,
        });

        GlobalAudioManager.stop();
        GlobalAudioManager.clearQueue();
      },
      resetNarration: () => {
        setState({
          narrationAudios: [],
          isLoading: false,
          isDirty: false,
          error: undefined,
          isPlaying: false,
        });

        GlobalAudioManager.stop();
        GlobalAudioManager.clearQueue();
      },
      moveEffectAudio: (objectId, newStart) => {
        const { audioEffects, getEffectAudio } = getState();
        const { conflictObject, conflictType } = useAudioEffectStore.getState();

        const currentAudio = getEffectAudio(objectId);

        if (!currentAudio) {
          return;
        }

        const otherAudiosEffects = removeAudioFromList(objectId, getAudiosAfterTime(newStart, audioEffects)).map(
          (audio) => audio.objectId,
        );

        const recursivePush = (startingTime: number, audioIdsToTest: string[]) => {
          for (const audioId of audioIdsToTest) {
            const audio = audioEffects.find((audio) => audio.objectId === audioId);
            if (audio) {
              if (audio.start < startingTime) {
                audio.start = startingTime;
                audio.end = audio.start + audio.duration;
              } else {
                break;
              }
            }
          }
        };

        // Conflict
        if (conflictObject) {
          const conflictAudio = getEffectAudio(conflictObject);
          // End conflict visually
          useAudioEffectStore.setState({
            conflictObject: null,
          });

          if (!conflictAudio) {
            return;
          }

          if (conflictType === ConflictType.LEFT) {
            currentAudio.start = conflictAudio.start;
            // Since we can drop it before the actual conflictAudio.start time
            //  It won't be initially in the otherPrimaryNarrationAudios list
            //  Needs to be added manually in this specific case
          } else {
            currentAudio.start = getEffectAudio(conflictObject)?.end ?? 0;
          }
        } else {
          currentAudio.start = newStart;
        }

        currentAudio.end = currentAudio.start + currentAudio.duration;

        // Recursively push conflicting elements
        if (otherAudiosEffects.length > 0) {
          recursivePush(currentAudio.start + currentAudio.duration, otherAudiosEffects);
        }

        setState({
          audioEffects: getSortedAudios([...audioEffects]) as EffectAudioState[],
        });
      },
      moveNarrationAudio: (objectId, newStart) => {
        const { narrationAudios, getNarrationAudio } = getState();
        const { conflictObject, conflictType } = useNarrationStore.getState();

        const currentAudio = getNarrationAudio(objectId);

        if (!currentAudio) {
          return;
        }

        const primaryNarrationAudios = getAudiosByLanguage(LanguageType.PRIMARY, narrationAudios);
        const secondaryNarrationAudios = getAudiosByLanguage(LanguageType.SECONDARY, narrationAudios);

        const otherPrimaryNarrationAudioIds = removeAudioFromList(
          objectId,
          getAudiosAfterTime(newStart, primaryNarrationAudios),
        ).map((audio) => audio.objectId);

        const recursivePush = (startingTime: number, audioIdsToTest: string[]) => {
          for (const audioId of audioIdsToTest) {
            const audio = narrationAudios.find((audio) => audio.objectId === audioId);

            if (audio) {
              if (audio.start < startingTime) {
                const secondary = getSecondary(audio.objectId, secondaryNarrationAudios);
                audio.start = startingTime;
                audio.end = audio.start + audio.duration;

                startingTime = startingTime + (secondary ? getBiggestDuration([audio, secondary]) : audio.duration);
              } else {
                // If the very first audio does not conflict, the rest won't as well...
                break;
              }
            }
          }
        };

        // Conflict
        if (conflictObject) {
          const conflictAudio = getNarrationAudio(conflictObject);
          // End conflict visually
          useNarrationStore.setState({
            conflictObject: null,
          });

          if (!conflictAudio) {
            return;
          }

          if (conflictType === ConflictType.LEFT) {
            currentAudio.start = conflictAudio.start;
            // Since we can drop it before the actual conflictAudio.start time
            //  It won't be initially in the otherPrimaryNarrationAudios list
            //  Needs to be added manually in this specific case
            otherPrimaryNarrationAudioIds.unshift(conflictAudio.objectId);
          } else {
            currentAudio.start = getGroupEnd(conflictObject, narrationAudios);
          }
        } else {
          currentAudio.start = newStart;
        }

        // Calculate end
        currentAudio.end = currentAudio.start + currentAudio.duration;

        // Recursively push conflicting elements
        if (otherPrimaryNarrationAudioIds.length > 0) {
          const secondaryAudio = getSecondary(currentAudio.objectId, secondaryNarrationAudios);
          const duration = secondaryAudio ? getBiggestDuration([currentAudio, secondaryAudio]) : currentAudio.duration;

          recursivePush(currentAudio.start + duration, otherPrimaryNarrationAudioIds);
        }

        // Update secondary
        const primaryAudiosMap = Object.fromEntries(primaryNarrationAudios.map((audio) => [audio.objectId, audio]));
        secondaryNarrationAudios.forEach((secondaryAudio) => {
          if (secondaryAudio?.parentObjectId) {
            secondaryAudio.start = primaryAudiosMap[secondaryAudio.parentObjectId].start;
            secondaryAudio.end = secondaryAudio.start + secondaryAudio.duration;
          }
        });

        // Apply changes
        setState({
          narrationAudios: getSortedAudios([...narrationAudios]) as NarrationAudioState[],
        });
      },
      replaceEffectAudio: async (objectId, audioProps) => {
        const { getEffectAudio, removeEffectAudio, loadEffectAudio, selectAudio } = getState();
        const targetAudio = getEffectAudio(objectId);

        if (!targetAudio) {
          return null;
        }

        // Remove previous audio
        removeEffectAudio(targetAudio.objectId);

        // Load new one
        await loadEffectAudio(audioProps.objectId, audioProps.input, {
          ...targetAudio,
          ...audioProps,
        });

        selectAudio(audioProps.objectId);
      },
      replaceNarrationAudio: async (objectId, audioProps) => {
        const { getNarrationAudio, removeNarrationAudio, loadNarrationAudio, selectAudio } = getState();
        const targetAudio = getNarrationAudio(objectId);

        if (!targetAudio) {
          return null;
        }

        // Remove previous audio
        removeNarrationAudio(targetAudio.objectId);

        // Load new one
        await loadNarrationAudio(audioProps.objectId, audioProps.input, {
          ...targetAudio,
          ...audioProps,
          savedText: audioProps?.ttsProps?.narrationText ?? targetAudio.savedText,
          ttsProps: audioProps?.ttsProps,
        });

        // Update timeline
        // moveNarrationAudio(audioProps.objectId, audioProps.start);

        // Select
        selectAudio(audioProps.objectId);
      },
      getAudioControls: (objectId) => {
        console.debug(`Get audio controls for ${objectId}`);
      },
      getSequenceControls: (objectIds) => {
        const { narrationAudios, audioEffects, selectedLanguage } = getState();
        // FORCING PRIMARY UNTIL WE  HAVE A LANGUAGE SWITCH
        const narration = objectIds
          ? narrationAudios.filter((audio) => objectIds.includes(audio.objectId))
          : getAudiosByLanguage(selectedLanguage, narrationAudios);

        const audios = getSortedAudios([...narration, ...audioEffects]);

        return {
          audios,
          // Actions
          play: (startTime) => {
            GlobalAudioManager.play(
              audios.map((audio) => ({ objectId: audio.objectId, start: audio.start })),
              startTime,
            );
          },
          pause: () => GlobalAudioManager.pause(),
          stop: () => GlobalAudioManager.stop(),
          resume: () => GlobalAudioManager.resume(),
          // Flags
          ready: audios.every((audio) => !audio.loading),
          playing: audios.some((audio) => audio.playing),
          loading: audios.some((audio) => audio.loading),
        };
      },
    };
  },
);

/**
 * Listening to GLOBAL AUDIO events
 */

// Controls

GlobalAudioManager.on(EAudioManagerEvents.PLAY, () => {
  useAudioManagerStore.setState({ isPlaying: true });
});

GlobalAudioManager.on(EAudioManagerEvents.PAUSE, () => {
  useAudioManagerStore.setState({ isPlaying: false });
});

GlobalAudioManager.on(EAudioManagerEvents.RESUME, () => {
  useAudioManagerStore.setState({ isPlaying: true });
});

GlobalAudioManager.on(EAudioManagerEvents.STOP, () => {
  useAudioManagerStore.setState({ isPlaying: false });
});

// General loading

GlobalAudioManager.on(EAudioManagerEvents.LOADING_START, () => {
  useAudioManagerStore.setState({ isLoading: true });
});

GlobalAudioManager.on(EAudioManagerEvents.LOADING_FINISH, () => {
  useAudioManagerStore.setState({ isLoading: false });
});

// Audio

GlobalAudioManager.on(EAudioManagerEvents.AUDIO_LOAD_START, (audio: AudioSource) => {
  const { audioTypes, updateNarrationAudio, updateEffectAudio } = useAudioManagerStore.getState();
  const type = audioTypes.get(audio.objectId);
  const update = { loading: true };

  if (type === EInteractiveAudioType.NARRATION) {
    updateNarrationAudio(audio.objectId, update);
  } else if (type === EInteractiveAudioType.EFFECT) {
    updateEffectAudio(audio.objectId, update);
  }
});

// GlobalAudioManager.on(EAudioManagerEvents.AUDIO_LOAD_FINISH, (audio: AudioSource) => {
//   useAudioManagerStore.getState().updateNarrationAudio(audio.objectId, {
//     loading: false,
//   });
// });

GlobalAudioManager.on(EAudioManagerEvents.AUDIO_ADD, (audio: LoadedAudio) => {
  // For now, duration is the only property that needs loading to be completely sure
  const { updateEffectAudio, updateNarrationAudio, audioTypes } = useAudioManagerStore.getState();
  const type = audioTypes.get(audio.objectId);

  if (type === EInteractiveAudioType.NARRATION) {
    updateNarrationAudio(audio.objectId, {
      loading: false,
      ready: true,
      start: audio.start,
      duration: audio.duration,
      end: audio.end,
    });
  } else if (type === EInteractiveAudioType.EFFECT) {
    updateEffectAudio(audio.objectId, {
      loading: false,
      ready: true,
      start: audio.start,
      duration: audio.duration,
      end: audio.end,
    });
  }
});

GlobalAudioManager.on(EAudioManagerEvents.AUDIO_DELETE, (audio: AudioSource) => {
  const { audioTypes, removeNarrationAudio, removeEffectAudio } = useAudioManagerStore.getState();
  const type = audioTypes.get(audio.objectId);

  if (type === EInteractiveAudioType.NARRATION) {
    removeNarrationAudio(audio.objectId);
  } else if (type === EInteractiveAudioType.EFFECT) {
    removeEffectAudio(audio.objectId);
  }
});

// Track Control

GlobalAudioManager.on(EAudioManagerEvents.TRACK_START, (audio: AudioSource) => {
  const { audioTypes, updateEffectAudio, updateNarrationAudio } = useAudioManagerStore.getState();
  const type = audioTypes.get(audio.objectId);
  const update = { playing: true };

  if (type === EInteractiveAudioType.NARRATION) {
    updateNarrationAudio(audio.objectId, update);
  } else if (type === EInteractiveAudioType.EFFECT) {
    updateEffectAudio(audio.objectId, update);
  }
});

GlobalAudioManager.on(EAudioManagerEvents.TRACK_STOP, (audio: AudioSource) => {
  const { audioTypes, updateEffectAudio, updateNarrationAudio } = useAudioManagerStore.getState();
  const type = audioTypes.get(audio.objectId);
  const update = { playing: false };

  if (type === EInteractiveAudioType.NARRATION) {
    updateNarrationAudio(audio.objectId, update);
  } else if (type === EInteractiveAudioType.EFFECT) {
    updateEffectAudio(audio.objectId, update);
  }
});

export const getAudioSource = (objectId: string): AudioSource | null => {
  return GlobalAudioManager.getAudioById(objectId);
};
