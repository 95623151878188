import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef, useCallback, useMemo } from "react";
import {
  Viewer,
  ViewerConfig,
  PanoData,
  AnimateOptions,
  CssSize,
  ExtendedPosition,
  UpdatableViewerConfig,
  events,
  PluginConstructor,
  NavbarCustomButton,
} from "@photo-sphere-viewer/core";
import styles from "./react-photo-sphere-viewer.module.css";
import "@photo-sphere-viewer/core/index.css";

const omittedProps = [
  "src",
  "height",
  "width",
  "containerClass",
  "littlePlanet",
  "onPositionChange",
  "onZoomChange",
  "onClick",
  "onDblclick",
  "onReady",
];
type MakeOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export interface Props extends MakeOptional<ViewerConfig, "container"> {
  src: string;
  navbar?: boolean | string | Array<string | NavbarCustomButton>;
  height: string;
  width?: string;
  containerClass?: string;
  littlePlanet?: boolean;
  fishEye?: boolean | number;
  lang?: {
    zoom: string;
    zoomOut: string;
    zoomIn: string;
    moveUp: string;
    moveDown: string;
    moveLeft: string;
    moveRight: string;
    download: string;
    fullscreen: string;
    menu: string;
    close: string;
    twoFingers: string;
    ctrlZoom: string;
    loadError: string;
    [K: string]: string;
  };
  // Events
  onPositionChange?(lat: number, lng: number, instance: Viewer): void;
  onZoomChange?(data: events.ZoomUpdatedEvent & { type: "zoom-updated" }, instance: Viewer): void;
  onClick?(data: events.ClickEvent & { type: "click" }, instance: Viewer): void;
  onDblclick?(data: events.ClickEvent & { type: "dblclick" }, instance: Viewer): void;
  onReady?(instance: Viewer): void;
}

const defaultNavbar = ["autorotate", "zoom", "fullscreen"];

function adaptOptions(options: Props): ViewerConfig {
  const adaptedOptions = { ...options };
  for (const key in adaptedOptions) {
    if (omittedProps.includes(key)) {
      delete adaptedOptions[key];
    }
  }
  return adaptedOptions as ViewerConfig;
}

function map(_in: number, inMin: number, inMax: number, outMin: number, outMax: number): number {
  return ((_in - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

function filterNavbar(
  navbar?: boolean | string | Array<string | NavbarCustomButton>,
): false | Array<string | NavbarCustomButton> {
  if (navbar == null) return defaultNavbar;
  if (!Array.isArray(navbar)) {
    if (typeof navbar === "string") {
      return navbar === "" ? false : [navbar];
    }
    return navbar ? defaultNavbar : false;
  }
  return navbar;
}

function useDomElement(): [HTMLDivElement | undefined, (r: HTMLDivElement) => void] {
  const [element, setElement] = useState<HTMLDivElement>();
  const ref = useCallback(
    (r: HTMLDivElement) => {
      if (r && r !== element) {
        setElement(r);
      }
    },
    [element],
  );
  return [element, ref];
}

export interface ViewerAPI {
  animate(options: AnimateOptions): void;
  destroy(): void;
  rotate(options: { x: number; y: number }): void;
  setOption(option: keyof ViewerConfig, value: unknown): void;
  setOptions(options: ViewerConfig): void;
  getCurrentNavbar(): (string | object)[] | void;
  zoom(value: number): void;
  zoomIn(): void;
  zoomOut(): void;
  resize(size: CssSize): void;
  enterFullscreen(): void;
  exitFullscreen(): void;
  toggleFullscreen(): void;
  isFullscreenEnabled(): boolean | void;
  startAutoRotate(): void;
  stopAutoRotate(): void;
  getPlugin(pluginName: string): unknown | void;
  getPosition(): unknown | void; // Specify the return type
  getZoomLevel(): unknown | void; // Specify the return type
  getSize(): unknown | void; // Specify the return type
  needsUpdate(): boolean | void;
  autoSize(): void;
  setPanorama(path: string, options?: object): void;
  setOverlay(path: string, opacity?: number): void;
  toggleAutorotate(): void;
  showError(message: string): void;
  hideError(): void;
  startKeyboardControl(): void;
  stopKeyboardControl(): void;
}

const ReactPhotoSphereViewer = forwardRef<ViewerAPI, Props>((props, ref): React.ReactElement => {
  const [sphereElement, setRef] = useDomElement();
  const options = useMemo(
    () => ({ ...props }),
    [
      // recreate options when individual props change
      props.src,
      props.navbar,
      props.height,
      props.width,
      props.containerClass,
      props.littlePlanet,
      props.fishEye,
      props.lang,
      props.onPositionChange,
      props.onZoomChange,
      props.onClick,
      props.onDblclick,
      props.onReady,
      props.moveSpeed,
      props.zoomSpeed,
      props.moveInertia,
      props.mousewheel,
      props.mousemove,
      props.mousewheelCtrlKey,
      props.touchmoveTwoFingers,
      props.useXmpData,
      props.panoData,
      props.requestHeaders,
      props.canvasBackground,
      props.withCredentials,
      props.keyboard,
      props.plugins,
      props.sphereCorrection,
      props.minFov,
      props.maxFov,
      props.defaultZoomLvl,
      props.defaultYaw,
      props.defaultPitch,
    ],
  );
  const spherePlayerInstance = useRef<Viewer | null>(null);
  const [spherePlayer, setSpherePlayer] = useState<Viewer | null>(null);
  let LITTLEPLANET_MAX_ZOOM = 130;
  const [LITTLEPLANET_DEF_LAT] = useState(-90);
  const [LITTLEPLANET_FISHEYE] = useState(2);
  const [LITTLEPLANET_DEF_ZOOM] = useState(0);
  const [currentNavbar, setCurrentNavbar] = useState<(string | object)[]>(defaultNavbar);

  useEffect(() => {
    function handleResize() {
      const aspectRatio = window.innerWidth / window.innerHeight;
      LITTLEPLANET_MAX_ZOOM = Math.floor(map(aspectRatio, 0.5, 1.8, 140, 115));
    }
    // Add event listener
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let littlePlanetEnabled = true;

    const positionUpdateHandler = (
      position: events.PositionUpdatedEvent & {
        type: "position-updated";
      },
    ) => {
      if (options.onPositionChange) {
        options.onPositionChange(position.position.pitch, position.position.yaw, spherePlayerInstance.current);
      }
    };
    if (sphereElement && !spherePlayerInstance.current) {
      const _c = new Viewer({
        ...adaptOptions(options),
        container: sphereElement,
        panorama: options.src,
        size: {
          height: options.height,
          width: options.width || "100px",
        },
        fisheye: options.littlePlanet ? LITTLEPLANET_FISHEYE : options.fisheye || false,
        minFov: options.minFov || 30,
        maxFov: options.littlePlanet ? LITTLEPLANET_MAX_ZOOM : options.maxFov || 90,
        defaultZoomLvl: options.littlePlanet
          ? LITTLEPLANET_DEF_ZOOM
          : typeof options.defaultZoomLvl === "number"
          ? options.defaultZoomLvl
          : 50,
        defaultYaw: options.defaultYaw || 0,
        defaultPitch: options.littlePlanet ? LITTLEPLANET_DEF_LAT : options.defaultPitch || 0,
        sphereCorrection: options.sphereCorrection || {
          pan: 0,
          tilt: 0,
          roll: 0,
        },
        moveSpeed: options.moveSpeed || 1,
        zoomSpeed: options.zoomSpeed || 1,
        // when it undefined, = true, then use input value.
        // The input value maybe false, value || true => false => true
        moveInertia: options.moveInertia ?? true,
        mousewheel: options.littlePlanet ? false : options.mousewheel ?? true,
        mousemove: options.mousemove ?? true,
        mousewheelCtrlKey: options.mousewheelCtrlKey || false,
        touchmoveTwoFingers: options.touchmoveTwoFingers || false,
        useXmpData: options.useXmpData ?? true,
        panoData: options.panoData || ({} as PanoData),
        requestHeaders: options.requestHeaders || {},
        canvasBackground: options.canvasBackground || "#000",
        withCredentials: options.withCredentials || false,
        navbar: filterNavbar(options.navbar),
        lang: options.lang || ({} as keyof Props["lang"]),
        keyboard: options.keyboard || {},
        plugins: [...(options.plugins ? (options.plugins as PluginConstructor[]) : [])],
      });
      _c.addEventListener(
        "ready",
        () => {
          if (options.onReady) {
            options.onReady(_c);
          }
        },
        { once: true },
      );
      _c.addEventListener("click", (data: events.ClickEvent & { type: "click" }) => {
        if (options.onClick) {
          options.onClick(data, _c);
        }
        if (options.littlePlanet && littlePlanetEnabled) {
          littlePlanetEnabled = false;
          // fly inside the sphere
          _c.animate({
            yaw: 0,
            pitch: LITTLEPLANET_DEF_LAT,
            zoom: 75,
            speed: "3rpm",
          }).then(() => {
            // watch on the sky
            _c.animate({
              yaw: 0,
              pitch: 0,
              zoom: 90,
              speed: "10rpm",
            }).then(() => {
              // Disable Little Planet.
              const p = _c.getPlugin("autorotate") as AutorotatePlugin;
              if (p) p.start();
              _c.setOption("maxFov", options.maxFov || 70);
              _c.setOption("mousewheel", options.mousewheel ?? true);
            });
          });
        }
      });
      _c.addEventListener("dblclick", (data: events.ClickEvent & { type: "dblclick" }) => {
        if (options.onDblclick) {
          options.onDblclick(data, _c);
        }
      });
      _c.addEventListener("zoom-updated", (zoom: events.ZoomUpdatedEvent & { type: "zoom-updated" }) => {
        if (options.onZoomChange) {
          options.onZoomChange(zoom, _c);
        }
      });
      _c.addEventListener("position-updated", positionUpdateHandler);

      spherePlayerInstance.current = _c;
      // setSpherePlayer(_c);
    } else {
      if (spherePlayerInstance.current) {
        spherePlayerInstance.current.config.mousemove = options.mousemove ?? true;
        spherePlayerInstance.current.config.mousewheel = options.mousewheel ?? true;
        spherePlayerInstance.current.config.touchmoveTwoFingers = options.touchmoveTwoFingers || false;
        spherePlayerInstance.current.addEventListener("position-updated", positionUpdateHandler);
      }
    }
    return () => {
      spherePlayerInstance.current?.removeEventListener("position-updated", positionUpdateHandler);
    };
  }, [sphereElement, options]);

  useEffect(() => {
    if (spherePlayerInstance.current) {
      spherePlayerInstance.current.setPanorama(options.src, options);
    }
  }, [options.src]);

  const _imperativeHandle = () => ({
    viewer: spherePlayerInstance.current,
  });
  // Methods
  useImperativeHandle(ref, _imperativeHandle, [spherePlayerInstance.current, sphereElement, options, ref]);

  return <div className={options.containerClass || styles.container} ref={setRef} />;
});

ReactPhotoSphereViewer.displayName = "ReactPhotoSphereViewer";

export { ReactPhotoSphereViewer };
