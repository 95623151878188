import React from "react";
import blobUrlFormatHelper from "../../components/blobUrlFormatHelper";
import { ElementTypes, IBasicPageImageV2 } from "../../pageTypes/BasicPage_Player/components/IBasePage";
import AnnotationHighlight from "../../pageTypes/BasicPage_Player/components/BaseFreeForm/components/HighlightRect";
import { IKeyPressHandler } from "../../components/ObjectPropertyBox/models/IKeyPressHandler";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";
import type { BlurCutoutObject } from "../../types";
import { useObjectPosition } from "../../hooks/useObjectPosition";
import {
  useInteracitvityHotspotDispatch,
  InteractivityHotspotActionTypes,
} from "../../contexts/InteractivityHotspotProvider";
import { useObjectIsInTime } from "../../hooks/useObjectIsInTime";
import { objectOpacityFromRules } from "../../utils";
import { CroppedMoveableContainer } from "../../components/SmartObjects";

export interface ImagePropType {
  blobUrl: string;
  grayscale?: number;
  ffElement: IBasicPageImageV2;
  index: number;
  frames?: any[];
  framesCacheId?: string;
  kp: IKeyPressHandler;
  target: any;
  objectId: string;

  scale?: any[];
  rotate?: number;
  zIndex?: number;

  pixelTop?: number;
  pixelLeft?: number;
  pixelWidth?: number;
  pixelHeight?: number;
  clipTop?: number;
  clipLeft?: number;
  clipRight?: number;
  clipBottom?: number;
  clipPath?: string;

  maintainRatio?: boolean;

  height?: number;
  width?: number;
  left: number;
  top: number;

  version?: any;

  fontColor?: string;
  fontStyle?: string;
  fontWeight?: any;

  borderColor?: string;
  shadowColor?: string;
  textDecoration?: string;
  backgroundColor?: string;
  opacity?: number;
  visible?: boolean;
  ghost?: boolean;

  handleKeyPress: (e: React.KeyboardEvent<HTMLDivElement | SVGSVGElement>, kp: IKeyPressHandler) => void;
  loadImage: (e: React.SyntheticEvent<HTMLImageElement>, index: number, objectId: string) => void;
  selectTarget: (
    target: HTMLDivElement,
    index: number,
    type: ElementTypes,
    ref?: React.RefObject<HTMLElement | SVGSVGElement>,
  ) => void;
  handleImageDivClick: (index: number) => void;
}
const Image = (props: ImagePropType): JSX.Element => {
  const {
    blobUrl,
    index,
    loadImage,
    objectId,
    scale,
    clipTop,
    clipLeft,
    clipRight,
    clipBottom,
    fontColor,
    fontStyle,
    fontWeight,
    borderColor,
    shadowColor,
    version,
    width,
    left,
    top,
    rotate,
    height,
    maintainRatio,
    zIndex,
    textDecoration,
    backgroundColor,
    clipPath,
    opacity,
    visible,
    ghost,
  } = props;
  const scaleString: string = scale ? `scale(${scale[0]}, ${scale[1]})` : "";

  const {
    position: [x, y],
    size: [widthWithFrames, heightWithFrames],
    rotation,
    opacity: opacityValue,
  } = useObjectPosition(objectId, top, left, width, height, rotate, opacity);
  const isInTime = useObjectIsInTime(objectId);
  const objectsState = useObjectsState();
  const rotateString: string = rotation ? `rotate(${rotation}deg)` : "rotate(0deg)";
  const hasLegacyClipPath = ![clipTop, clipLeft, clipRight, clipBottom].every((c) => c === 0 || c === undefined);
  const clipP =
    hasLegacyClipPath && !clipPath
      ? `inset(${clipTop}px ${clipRight}px ${clipBottom}px ${clipLeft}px)`
      : clipPath
      ? clipPath
      : "";
  const objectsDispatch = useObjectsDispatch();
  const hotspotsDispatch = useInteracitvityHotspotDispatch();

  const isCropping = objectsState.isCropping;
  const isCroppedImage = clipPath ? true : false;
  const {
    top: topFromClipPath,
    right: rightFromClipPath,
    bottom: bottomFromClipPath,
    left: leftFromClipPath,
  } = clipPath ?? {};

  const clipContainerLeft = leftFromClipPath;
  const clipContainerTop = topFromClipPath;
  const clipContainerWidth = 100 - leftFromClipPath - rightFromClipPath;
  const clipContainerHeight = 100 - topFromClipPath - bottomFromClipPath;

  const transformString = `translate(${x}px, ${y}px) ${rotateString}`;

  const shouldContainerBeMoveable = !isCropping;

  return (
    <div
      className={`target ff-image guide-${objectId}`}
      id={`image-target-${index}`}
      data-objectId={shouldContainerBeMoveable ? objectId : ""}
      data-ref={objectId}
      key={index}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!objectId) return;
        let type = ObjectActionsType.SET_SELECTED_OBJECT;
        if (e.ctrlKey) {
          type = ObjectActionsType.ADD_SELECTED_OBJECT;
        }
        objectsDispatch({
          type,
          payload: { objectId },
        });
        hotspotsDispatch({
          type: InteractivityHotspotActionTypes.SET_CURRENT_HOTSPOT,
          payload: null,
        });
      }}
      onDoubleClick={() => props.handleImageDivClick(index)}
      tabIndex={0}
      style={{
        position: "absolute",
        width: `${widthWithFrames}px`,
        height: `${heightWithFrames}px`,
        clipPath: clipP,
        zIndex: zIndex,
        scale: `${scaleString}`,
        transform: transformString,
        color: `${fontColor}`,
        fontStyle: `${fontStyle}`,
        fontWeight: fontWeight,
        textDecoration: `${textDecoration}`,
        backgroundColor: `${backgroundColor}`,
        outline: `${borderColor}`,
        boxShadow: `${shadowColor}`,
        opacity: objectOpacityFromRules(opacityValue, visible, isInTime, ghost),
        filter: visible ? "none" : "grayScale(100%)",
      }}
    >
      <AnnotationHighlight objectId={objectId} />

      <div id="free-form-container" className="img-aspect-container">
        {!shouldContainerBeMoveable && (
          <CroppedMoveableContainer
            objectId={objectId}
            clipContainerTop={clipContainerTop}
            clipContainerLeft={clipContainerLeft}
            clipContainerWidth={clipContainerWidth}
            clipContainerHeight={clipContainerHeight}
            shouldContainerBeMoveable={shouldContainerBeMoveable}
          />
        )}
        <div className="free-form-element aspect-container-inside">
          <div className="free-form-inner aspect-centering">
            <img
              id={`ff-image-${index}`}
              src={blobUrlFormatHelper(blobUrl)}
              alt=""
              onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
                // we only want this to trigger if there is no version or if the version is less than 3
                if (!version || Number(version) < 3) {
                  loadImage(e, index, objectId);
                }
              }}
              style={{
                display: isInTime || ghost ? "block" : "none",
                width: "100%",
                height: maintainRatio ? "auto" : "100%",
                filter: typeof props.grayscale === "number" ? `grayscale(${props.grayscale}%)` : undefined,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Image;
